import { Icon, Box } from '@chakra-ui/react';

const SlotIcon = (props) => {
  return (
    <Box>
      <Icon
        viewBox="0 0 289 87"
        fill="none"
        {...props}
        transition={'all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.25s'}
      >
        <path
          d="M32.92 86.28C26.76 86.28 21.24 85.28 16.36 83.28C11.48 81.28 7.56001 78.32 4.60001 74.4C1.72001 70.48 0.200009 65.76 0.0400085 60.24H21.88C22.2 63.36 23.28 65.76 25.12 67.44C26.96 69.04 29.36 69.84 32.32 69.84C35.36 69.84 37.76 69.16 39.52 67.8C41.28 66.36 42.16 64.4 42.16 61.92C42.16 59.84 41.44 58.12 40 56.76C38.64 55.4 36.92 54.28 34.84 53.4C32.84 52.52 29.96 51.52 26.2 50.4C20.76 48.72 16.32 47.04 12.88 45.36C9.44001 43.68 6.48001 41.2 4.00001 37.92C1.52001 34.64 0.280008 30.36 0.280008 25.08C0.280008 17.24 3.12001 11.12 8.80001 6.72C14.48 2.24 21.88 0 31 0C40.28 0 47.76 2.24 53.44 6.72C59.12 11.12 62.16 17.28 62.56 25.2H40.36C40.2 22.48 39.2 20.36 37.36 18.84C35.52 17.24 33.16 16.44 30.28 16.44C27.8 16.44 25.8 17.12 24.28 18.48C22.76 19.76 22 21.64 22 24.12C22 26.84 23.28 28.96 25.84 30.48C28.4 32 32.4 33.64 37.84 35.4C43.28 37.24 47.68 39 51.04 40.68C54.48 42.36 57.44 44.8 59.92 48C62.4 51.2 63.64 55.32 63.64 60.36C63.64 65.16 62.4 69.52 59.92 73.44C57.52 77.36 54 80.48 49.36 82.8C44.72 85.12 39.24 86.28 32.92 86.28Z"
          fill="white"
        />

        <path
          d="M96.7881 69.6H123.668V85.44H76.2681V1.2H96.7881V69.6Z"
          fill="white"
        />

        <path
          d="M173.296 86.28C165.376 86.28 158.096 84.44 151.456 80.76C144.896 77.08 139.656 71.96 135.736 65.4C131.896 58.76 129.976 51.32 129.976 43.08C129.976 34.84 131.896 27.44 135.736 20.88C139.656 14.32 144.896 9.2 151.456 5.52C158.096 1.84 165.376 0 173.296 0C181.216 0 188.456 1.84 195.016 5.52C201.656 9.2 206.856 14.32 210.616 20.88C214.456 27.44 216.376 34.84 216.376 43.08C216.376 51.32 214.456 58.76 210.616 65.4C206.776 71.96 201.576 77.08 195.016 80.76C188.456 84.44 181.216 86.28 173.296 86.28ZM173.296 67.56C180.016 67.56 185.376 65.32 189.376 60.84C193.456 56.36 195.496 50.44 195.496 43.08C195.496 35.64 193.456 29.72 189.376 25.32C185.376 20.84 180.016 18.6 173.296 18.6C166.496 18.6 161.056 20.8 156.976 25.2C152.976 29.6 150.976 35.56 150.976 43.08C150.976 50.52 152.976 56.48 156.976 60.96C161.056 65.36 166.496 67.56 173.296 67.56Z"
          fill="white"
        />

        <path
          d="M288.392 1.2V17.64H266.072V85.44H245.552V17.64H223.232V1.2H288.392Z"
          fill="white"
        />

      </Icon>
    </Box>
  )
}

export default SlotIcon