import { Icon, Box } from '@chakra-ui/react'

const Casino = (props) => {
  return (
    <Box>
      <Icon
        viewBox="0 0 488 87"
        fill="none"
        {...props}
        transition={'all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.25s'}
      >
        <path
          d="M0.960022 43.76C0.960022 35.44 2.76002 28.04 6.36002 21.56C9.96002 15 14.96 9.92 21.36 6.32C27.84 2.64 35.16 0.799995 43.32 0.799995C53.32 0.799995 61.88 3.43999 69 8.71999C76.12 14 80.88 21.2 83.28 30.32H60.72C59.04 26.8 56.64 24.12 53.52 22.28C50.48 20.44 47 19.52 43.08 19.52C36.76 19.52 31.64 21.72 27.72 26.12C23.8 30.52 21.84 36.4 21.84 43.76C21.84 51.12 23.8 57 27.72 61.4C31.64 65.8 36.76 68 43.08 68C47 68 50.48 67.08 53.52 65.24C56.64 63.4 59.04 60.72 60.72 57.2H83.28C80.88 66.32 76.12 73.52 69 78.8C61.88 84 53.32 86.6 43.32 86.6C35.16 86.6 27.84 84.8 21.36 81.2C14.96 77.52 9.96002 72.44 6.36002 65.96C2.76002 59.48 0.960022 52.08 0.960022 43.76Z"
          fill="white"
        />
        <path
          d="M148.286 71.12H116.846L111.806 86H90.3263L120.806 1.76H144.566L175.046 86H153.326L148.286 71.12ZM143.006 55.28L132.566 24.44L122.246 55.28H143.006Z"
          fill="white"
        />
        <path
          d="M214.803 86.84C208.643 86.84 203.123 85.84 198.243 83.84C193.363 81.84 189.443 78.88 186.483 74.96C183.603 71.04 182.083 66.32 181.923 60.8H203.763C204.083 63.92 205.163 66.32 207.003 68C208.843 69.6 211.243 70.4 214.203 70.4C217.243 70.4 219.643 69.72 221.403 68.36C223.163 66.92 224.043 64.96 224.043 62.48C224.043 60.4 223.323 58.68 221.883 57.32C220.523 55.96 218.803 54.84 216.723 53.96C214.723 53.08 211.843 52.08 208.083 50.96C202.643 49.28 198.203 47.6 194.763 45.92C191.323 44.24 188.363 41.76 185.883 38.48C183.403 35.2 182.163 30.92 182.163 25.64C182.163 17.8 185.003 11.68 190.683 7.28C196.363 2.8 203.763 0.559998 212.883 0.559998C222.163 0.559998 229.643 2.8 235.323 7.28C241.003 11.68 244.043 17.84 244.443 25.76H222.243C222.083 23.04 221.083 20.92 219.243 19.4C217.403 17.8 215.043 17 212.163 17C209.683 17 207.683 17.68 206.163 19.04C204.643 20.32 203.883 22.2 203.883 24.68C203.883 27.4 205.163 29.52 207.723 31.04C210.283 32.56 214.283 34.2 219.723 35.96C225.163 37.8 229.563 39.56 232.923 41.24C236.363 42.92 239.323 45.36 241.803 48.56C244.283 51.76 245.523 55.88 245.523 60.92C245.523 65.72 244.283 70.08 241.803 74C239.403 77.92 235.883 81.04 231.243 83.36C226.603 85.68 221.123 86.84 214.803 86.84Z"
          fill="white"
        />
        <path
          d="M278.671 1.76V86H258.151V1.76H278.671Z"
          fill="white"
        />
        <path
          d="M368.902 86H348.382L314.062 34.04V86H293.542V1.76H314.062L348.382 53.96V1.76H368.902V86Z"
          fill="white"
        />
        <path d="M423.616 86.84C415.696 86.84 408.416 85 401.776 81.32C395.216 77.64 389.976 72.52 386.056 65.96C382.216 59.32 380.296 51.88 380.296 43.64C380.296 35.4 382.216 28 386.056 21.44C389.976 14.88 395.216 9.76 401.776 6.08C408.416 2.4 415.696 0.559998 423.616 0.559998C431.536 0.559998 438.776 2.4 445.336 6.08C451.976 9.76 457.176 14.88 460.936 21.44C464.776 28 466.696 35.4 466.696 43.64C466.696 51.88 464.776 59.32 460.936 65.96C457.096 72.52 451.896 77.64 445.336 81.32C438.776 85 431.536 86.84 423.616 86.84ZM423.616 68.12C430.336 68.12 435.696 65.88 439.696 61.4C443.776 56.92 445.816 51 445.816 43.64C445.816 36.2 443.776 30.28 439.696 25.88C435.696 21.4 430.336 19.16 423.616 19.16C416.816 19.16 411.376 21.36 407.296 25.76C403.296 30.16 401.296 36.12 401.296 43.64C401.296 51.08 403.296 57.04 407.296 61.52C411.376 65.92 416.816 68.12 423.616 68.12Z" fill="white" />

      </Icon>
    </Box>
  )
}

export default Casino