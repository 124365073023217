import { Icon, Box } from '@chakra-ui/react';

const FishIcon = (props) => {
  return (
    <Box>
      <Icon
        viewBox="0 0 469 87"
        fill="none"
        {...props}
        transition={'all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.25s'}
      >
        <path
          d="M55.28 1.76V18.2H20.96V35.96H46.64V51.92H20.96V86H0.440002V1.76H55.28Z"
          fill="white"
        />

        <path
          d="M86.585 1.76V86H66.065V1.76H86.585Z"
          fill="white"
        />

        <path
          d="M131.936 86.84C125.776 86.84 120.256 85.84 115.376 83.84C110.496 81.84 106.576 78.88 103.616 74.96C100.736 71.04 99.2156 66.32 99.0556 60.8H120.896C121.216 63.92 122.296 66.32 124.136 68C125.976 69.6 128.376 70.4 131.336 70.4C134.376 70.4 136.776 69.72 138.536 68.36C140.296 66.92 141.176 64.96 141.176 62.48C141.176 60.4 140.456 58.68 139.016 57.32C137.656 55.96 135.936 54.84 133.856 53.96C131.856 53.08 128.976 52.08 125.216 50.96C119.776 49.28 115.336 47.6 111.896 45.92C108.456 44.24 105.496 41.76 103.016 38.48C100.536 35.2 99.2956 30.92 99.2956 25.64C99.2956 17.8 102.136 11.68 107.816 7.28C113.496 2.8 120.896 0.559998 130.016 0.559998C139.296 0.559998 146.776 2.8 152.456 7.28C158.136 11.68 161.176 17.84 161.576 25.76H139.376C139.216 23.04 138.216 20.92 136.376 19.4C134.536 17.8 132.176 17 129.296 17C126.816 17 124.816 17.68 123.296 19.04C121.776 20.32 121.016 22.2 121.016 24.68C121.016 27.4 122.296 29.52 124.856 31.04C127.416 32.56 131.416 34.2 136.856 35.96C142.296 37.8 146.696 39.56 150.056 41.24C153.496 42.92 156.456 45.36 158.936 48.56C161.416 51.76 162.656 55.88 162.656 60.92C162.656 65.72 161.416 70.08 158.936 74C156.536 77.92 153.016 81.04 148.376 83.36C143.736 85.68 138.256 86.84 131.936 86.84Z"
          fill="white"
        />

        <path
          d="M248.244 1.76V86H227.724V51.32H195.804V86H175.284V1.76H195.804V34.76H227.724V1.76H248.244Z"
          fill="white"
        />

        <path
          d="M283.577 1.76V86H263.057V1.76H283.577Z"
          fill="white"
        />

        <path
          d="M373.808 86H353.288L318.968 34.04V86H298.448V1.76H318.968L353.288 53.96V1.76H373.808V86Z"
          fill="white"
        />

        <path
          d="M444.602 28.4C443.082 25.6 440.882 23.48 438.002 22.04C435.202 20.52 431.882 19.76 428.042 19.76C421.402 19.76 416.082 21.96 412.082 26.36C408.082 30.68 406.082 36.48 406.082 43.76C406.082 51.52 408.162 57.6 412.322 62C416.562 66.32 422.362 68.48 429.722 68.48C434.762 68.48 439.002 67.2 442.442 64.64C445.962 62.08 448.522 58.4 450.122 53.6H424.082V38.48H468.722V57.56C467.202 62.68 464.602 67.44 460.922 71.84C457.322 76.24 452.722 79.8 447.122 82.52C441.522 85.24 435.202 86.6 428.162 86.6C419.842 86.6 412.402 84.8 405.842 81.2C399.362 77.52 394.282 72.44 390.602 65.96C387.002 59.48 385.202 52.08 385.202 43.76C385.202 35.44 387.002 28.04 390.602 21.56C394.282 15 399.362 9.92 405.842 6.32C412.322 2.64 419.722 0.799995 428.042 0.799995C438.122 0.799995 446.602 3.24 453.482 8.12C460.442 13 465.042 19.76 467.282 28.4H444.602Z"
          fill="white"
        />

      </Icon>
    </Box>
  )
}

export default FishIcon