import { Icon } from '@chakra-ui/react'

const Events = (props) => {
  return (
    <Icon
      viewBox="0 0 462 87"
      fill="none"
      {...props}
      transition={'all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.25s'}
    >
      <path
        d="M20.96 18.2V35.24H48.44V51.08H20.96V69.56H52.04V86H0.440002V1.76H52.04V18.2H20.96Z"
        fill="white"
      />
      <path
        d="M144.442 1.76L114.562 86H88.8819L59.0019 1.76H80.8419L101.722 65.36L122.722 1.76H144.442Z"
        fill="white"
      />
      <path
        d="M173.538 18.2V35.24H201.018V51.08H173.538V69.56H204.618V86H153.018V1.76H204.618V18.2H173.538Z"
        fill="white"
      />
      <path
        d="M293.3 86H272.78L238.46 34.04V86H217.94V1.76H238.46L272.78 53.96V1.76H293.3V86Z"
        fill="white"
      />
      <path
        d="M368.774 1.76V18.2H346.454V86H325.934V18.2H303.614V1.76H368.774Z"
        fill="white"
      />
      <path
        d="M409.553 86.84C403.393 86.84 397.873 85.84 392.993 83.84C388.113 81.84 384.193 78.88 381.233 74.96C378.353 71.04 376.833 66.32 376.673 60.8H398.513C398.833 63.92 399.913 66.32 401.753 68C403.593 69.6 405.993 70.4 408.953 70.4C411.993 70.4 414.393 69.72 416.153 68.36C417.913 66.92 418.793 64.96 418.793 62.48C418.793 60.4 418.073 58.68 416.633 57.32C415.273 55.96 413.553 54.84 411.473 53.96C409.473 53.08 406.593 52.08 402.833 50.96C397.393 49.28 392.953 47.6 389.513 45.92C386.073 44.24 383.113 41.76 380.633 38.48C378.153 35.2 376.913 30.92 376.913 25.64C376.913 17.8 379.753 11.68 385.433 7.28C391.113 2.8 398.513 0.559998 407.633 0.559998C416.913 0.559998 424.393 2.8 430.073 7.28C435.753 11.68 438.793 17.84 439.193 25.76H416.993C416.833 23.04 415.833 20.92 413.993 19.4C412.153 17.8 409.793 17 406.913 17C404.433 17 402.433 17.68 400.913 19.04C399.393 20.32 398.633 22.2 398.633 24.68C398.633 27.4 399.913 29.52 402.473 31.04C405.033 32.56 409.033 34.2 414.473 35.96C419.913 37.8 424.313 39.56 427.673 41.24C431.113 42.92 434.073 45.36 436.553 48.56C439.033 51.76 440.273 55.88 440.273 60.92C440.273 65.72 439.033 70.08 436.553 74C434.153 77.92 430.633 81.04 425.993 83.36C421.353 85.68 415.873 86.84 409.553 86.84Z"
        fill="white"
      />

    </Icon>
  )
}

export default Events