export const gameType = {
  slot: {
    text1: 'SLOT',
    text2: 'GAMES'
  },
  card: {
    text1: 'CHESS CARD',
    text2: 'GAMES'
  },
  fish: {
    text1: 'FISHING',
    text2: 'GAMES'
  },
  sport: {
    text1: 'SPORTS',
    text2: 'EVENTS'
  },
  esport: {
    text1: 'E-SPORTS',
    text2: null
  },
  live: {
    text1: 'LIVE',
    text2: 'CASINO'
  },
  lottery: {
    text1: 'LOTTERY',
    text2: 'GAMES'
  }
}