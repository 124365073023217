import { Icon, Box } from '@chakra-ui/react';

const LotteryIcon = (props) => {
  return (
    <Box>
      <Icon
        viewBox="0 0 510 87"
        fill="none"
        {...props}
        transition={'all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.25s'}
      >
        <path d="M20.96 70.16H47.84V86H0.440002V1.76H20.96V70.16Z" fill="white" />
        <path d="M97.4675 86.84C89.5475 86.84 82.2675 85 75.6275 81.32C69.0675 77.64 63.8275 72.52 59.9075 65.96C56.0675 59.32 54.1475 51.88 54.1475 43.64C54.1475 35.4 56.0675 28 59.9075 21.44C63.8275 14.88 69.0675 9.76 75.6275 6.08C82.2675 2.4 89.5475 0.559998 97.4675 0.559998C105.388 0.559998 112.628 2.4 119.188 6.08C125.828 9.76 131.028 14.88 134.788 21.44C138.628 28 140.548 35.4 140.548 43.64C140.548 51.88 138.628 59.32 134.788 65.96C130.948 72.52 125.748 77.64 119.188 81.32C112.628 85 105.388 86.84 97.4675 86.84ZM97.4675 68.12C104.188 68.12 109.548 65.88 113.548 61.4C117.628 56.92 119.668 51 119.668 43.64C119.668 36.2 117.628 30.28 113.548 25.88C109.548 21.4 104.188 19.16 97.4675 19.16C90.6675 19.16 85.2275 21.36 81.1475 25.76C77.1475 30.16 75.1475 36.12 75.1475 43.64C75.1475 51.08 77.1475 57.04 81.1475 61.52C85.2275 65.92 90.6675 68.12 97.4675 68.12Z" fill="white" />
        <path d="M212.563 1.76V18.2H190.243V86H169.723V18.2H147.403V1.76H212.563Z" fill="white" />
        <path d="M283.462 1.76V18.2H261.142V86H240.622V18.2H218.302V1.76H283.462Z" fill="white" />
        <path d="M314.28 18.2V35.24H341.76V51.08H314.28V69.56H345.36V86H293.76V1.76H345.36V18.2H314.28Z" fill="white" />
        <path d="M401.642 86L384.122 54.2H379.202V86H358.682V1.76H393.122C399.762 1.76 405.402 2.92 410.042 5.23999C414.762 7.55999 418.282 10.76 420.602 14.84C422.922 18.84 424.082 23.32 424.082 28.28C424.082 33.88 422.482 38.88 419.282 43.28C416.162 47.68 411.522 50.8 405.362 52.64L424.802 86H401.642ZM379.202 39.68H391.922C395.682 39.68 398.482 38.76 400.322 36.92C402.242 35.08 403.202 32.48 403.202 29.12C403.202 25.92 402.242 23.4 400.322 21.56C398.482 19.72 395.682 18.8 391.922 18.8H379.202V39.68Z" fill="white" />
        <path d="M509.203 1.76L480.043 58.16V86H459.523V58.16L430.363 1.76H453.643L469.963 37.04L486.163 1.76H509.203Z" fill="white" />

      </Icon>
    </Box>
  )
}

export default LotteryIcon