import { extendTheme } from "@chakra-ui/react";

const breakpoints = {
  sm: '375px',
  smd: '500px',
  mmd: '650px',
  md: '768px',
  lg: '991px',
  xl: '1200px',
  '2xl': '1440px',
  '3xl': '1920px',
  '4xl': '2560px'
}

const theme = extendTheme({ breakpoints });

export default theme;