import { Icon, Box } from '@chakra-ui/react';

const EsportsIcon = (props) => {
  return (
    <Box>
      <Icon
        viewBox="0 0 589 87"
        fill="none"
        {...props}
        transition={'all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.25s'}
      >
        <path d="M20.96 18.2V35.24H48.44V51.08H20.96V69.56H52.04V86H0.440002V1.76H52.04V18.2H20.96Z" fill="white" />
        <path d="M116.962 35.36V52.4H64.1619V35.36H116.962Z" fill="white" />
        <path d="M165.451 86.84C159.291 86.84 153.771 85.84 148.891 83.84C144.011 81.84 140.091 78.88 137.131 74.96C134.251 71.04 132.731 66.32 132.571 60.8H154.411C154.731 63.92 155.811 66.32 157.651 68C159.491 69.6 161.891 70.4 164.851 70.4C167.891 70.4 170.291 69.72 172.051 68.36C173.811 66.92 174.691 64.96 174.691 62.48C174.691 60.4 173.971 58.68 172.531 57.32C171.171 55.96 169.451 54.84 167.371 53.96C165.371 53.08 162.491 52.08 158.731 50.96C153.291 49.28 148.851 47.6 145.411 45.92C141.971 44.24 139.011 41.76 136.531 38.48C134.051 35.2 132.811 30.92 132.811 25.64C132.811 17.8 135.651 11.68 141.331 7.28C147.011 2.8 154.411 0.559998 163.531 0.559998C172.811 0.559998 180.291 2.8 185.971 7.28C191.651 11.68 194.691 17.84 195.091 25.76H172.891C172.731 23.04 171.731 20.92 169.891 19.4C168.051 17.8 165.691 17 162.811 17C160.331 17 158.331 17.68 156.811 19.04C155.291 20.32 154.531 22.2 154.531 24.68C154.531 27.4 155.811 29.52 158.371 31.04C160.931 32.56 164.931 34.2 170.371 35.96C175.811 37.8 180.211 39.56 183.571 41.24C187.011 42.92 189.971 45.36 192.451 48.56C194.931 51.76 196.171 55.88 196.171 60.92C196.171 65.72 194.931 70.08 192.451 74C190.051 77.92 186.531 81.04 181.891 83.36C177.251 85.68 171.771 86.84 165.451 86.84Z" fill="white" />
        <path d="M272.999 28.88C272.999 33.76 271.879 38.24 269.639 42.32C267.399 46.32 263.959 49.56 259.319 52.04C254.679 54.52 248.919 55.76 242.039 55.76H229.319V86H208.799V1.76H242.039C248.759 1.76 254.439 2.92 259.079 5.23999C263.719 7.55999 267.199 10.76 269.519 14.84C271.839 18.92 272.999 23.6 272.999 28.88ZM240.479 39.44C244.399 39.44 247.319 38.52 249.239 36.68C251.159 34.84 252.119 32.24 252.119 28.88C252.119 25.52 251.159 22.92 249.239 21.08C247.319 19.24 244.399 18.32 240.479 18.32H229.319V39.44H240.479Z" fill="white" />
        <path d="M323.522 86.84C315.602 86.84 308.322 85 301.682 81.32C295.122 77.64 289.882 72.52 285.962 65.96C282.122 59.32 280.202 51.88 280.202 43.64C280.202 35.4 282.122 28 285.962 21.44C289.882 14.88 295.122 9.76 301.682 6.08C308.322 2.4 315.602 0.559998 323.522 0.559998C331.442 0.559998 338.682 2.4 345.242 6.08C351.882 9.76 357.082 14.88 360.842 21.44C364.682 28 366.602 35.4 366.602 43.64C366.602 51.88 364.682 59.32 360.842 65.96C357.002 72.52 351.802 77.64 345.242 81.32C338.682 85 331.442 86.84 323.522 86.84ZM323.522 68.12C330.242 68.12 335.602 65.88 339.602 61.4C343.682 56.92 345.722 51 345.722 43.64C345.722 36.2 343.682 30.28 339.602 25.88C335.602 21.4 330.242 19.16 323.522 19.16C316.722 19.16 311.282 21.36 307.202 25.76C303.202 30.16 301.202 36.12 301.202 43.64C301.202 51.08 303.202 57.04 307.202 61.52C311.282 65.92 316.722 68.12 323.522 68.12Z" fill="white" />
        <path d="M420.978 86L403.458 54.2H398.538V86H378.018V1.76H412.458C419.098 1.76 424.738 2.92 429.378 5.23999C434.098 7.55999 437.618 10.76 439.938 14.84C442.258 18.84 443.418 23.32 443.418 28.28C443.418 33.88 441.818 38.88 438.618 43.28C435.498 47.68 430.858 50.8 424.698 52.64L444.138 86H420.978ZM398.538 39.68H411.258C415.018 39.68 417.818 38.76 419.658 36.92C421.578 35.08 422.538 32.48 422.538 29.12C422.538 25.92 421.578 23.4 419.658 21.56C417.818 19.72 415.018 18.8 411.258 18.8H398.538V39.68Z" fill="white" />
        <path d="M516.899 1.76V18.2H494.579V86H474.059V18.2H451.739V1.76H516.899Z" fill="white" />
        <path d="M557.678 86.84C551.518 86.84 545.998 85.84 541.118 83.84C536.238 81.84 532.318 78.88 529.358 74.96C526.478 71.04 524.958 66.32 524.798 60.8H546.638C546.958 63.92 548.038 66.32 549.878 68C551.718 69.6 554.118 70.4 557.078 70.4C560.118 70.4 562.518 69.72 564.278 68.36C566.038 66.92 566.918 64.96 566.918 62.48C566.918 60.4 566.198 58.68 564.758 57.32C563.398 55.96 561.678 54.84 559.598 53.96C557.598 53.08 554.718 52.08 550.958 50.96C545.518 49.28 541.078 47.6 537.638 45.92C534.198 44.24 531.238 41.76 528.758 38.48C526.278 35.2 525.038 30.92 525.038 25.64C525.038 17.8 527.878 11.68 533.558 7.28C539.238 2.8 546.638 0.559998 555.758 0.559998C565.038 0.559998 572.518 2.8 578.198 7.28C583.878 11.68 586.918 17.84 587.318 25.76H565.118C564.958 23.04 563.958 20.92 562.118 19.4C560.278 17.8 557.918 17 555.038 17C552.558 17 550.558 17.68 549.038 19.04C547.518 20.32 546.758 22.2 546.758 24.68C546.758 27.4 548.038 29.52 550.598 31.04C553.158 32.56 557.158 34.2 562.598 35.96C568.038 37.8 572.438 39.56 575.798 41.24C579.238 42.92 582.198 45.36 584.678 48.56C587.158 51.76 588.398 55.88 588.398 60.92C588.398 65.72 587.158 70.08 584.678 74C582.278 77.92 578.758 81.04 574.118 83.36C569.478 85.68 563.998 86.84 557.678 86.84Z" fill="white" />

      </Icon>
    </Box>
  )
}

export default EsportsIcon