/*
  引入
  import { useSiteInfoData } from '../hooks/useSiteInfoData'
  // 取得站台資料
  const { data: res, isLoading, error } = useSiteInfoData(onSuccess, onError)

  const onSuccess = (res) => {}
  const onError = () => {

  }
*/
import { useRouter } from 'next/router';

// React Query
import {
  useQuery,
} from '@tanstack/react-query'
import { getSiteInfoAPI } from '../utils/api'


export const useSiteInfoData = (onSuccess = () => { }, onError = () => { }) => {
  const router = useRouter()

  return useQuery(
    ['siteinfo', router?.query?.vendor],
    async () => await getSiteInfoAPI(),
    {
      onSuccess,
      onError
    }
  )
}