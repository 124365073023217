import { Icon } from '@chakra-ui/react'
const PersonIcon = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" {...props}>
      <path d="M12.6433 0.75C10.0139 0.75 7.60838 1.63811 5.7063 3.13654L7.10489 4.49635C8.67128 3.35854 10.5734 2.69267 12.6433 2.69267C17.8461 2.6648 22.0698 6.91058 22.0698 12.1555C22.0698 17.4006 17.8459 21.6462 12.6433 21.6462C7.44065 21.6462 3.16086 17.4004 3.16086 12.1555C3.16086 10.879 3.41257 9.65808 3.88808 8.52032L4.50346 10.0466L6.06983 9.4084L4.92294 6.57782L4.27963 5.02386L2.76926 5.74531L0 7.02193L0.699494 8.54808L2.07016 7.90985C1.53865 9.21408 1.23096 10.6572 1.23096 12.1833C1.23096 18.4826 6.32182 23.5887 12.6155 23.5887C18.9091 23.5887 24 18.4826 24 12.1833C23.9996 5.85622 18.9086 0.750149 12.643 0.750149L12.6433 0.75Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M12.5584 11.3508C14.3814 11.3508 15.8592 9.88473 15.8592 8.07617C15.8592 6.26767 14.3814 4.80176 12.5584 4.80176C10.7355 4.80176 9.25785 6.26767 9.25785 8.07617C9.25785 9.88473 10.7355 11.3508 12.5584 11.3508ZM16.6895 18.4837C17.2325 18.2179 17.5375 17.6441 17.5375 17.0395L17.5374 17.0396C17.5568 16.982 17.5495 16.8973 17.5431 16.823C17.5402 16.7899 17.5375 16.7587 17.5374 16.7331C17.5095 16.0393 17.3975 15.4579 17.1458 14.8751C16.3905 13.0991 14.6283 11.878 12.5863 11.878C10.5163 11.878 8.75425 13.0991 7.99897 14.8751C7.71937 15.4579 7.55157 16.1042 7.55157 16.7703V17.0396C7.55157 17.6268 7.84805 18.1834 8.37353 18.4454C9.63441 19.0739 11.0498 19.4262 12.5585 19.4262C14.0341 19.4262 15.436 19.0971 16.6895 18.4837Z" fill="currentColor" />
    </Icon>
  )
}

export default PersonIcon
