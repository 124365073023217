import { Icon } from '@chakra-ui/react';

const Menu2Icon = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" {...props}>
      <path d="M0 12C0 13.1046 0.895432 14 2 14H22C23.1046 14 24 13.1046 24 12C24 10.8954 23.1046 10 22 10H2C0.895432 10 0 10.8954 0 12Z" fill="currentColor" />
    </Icon>
  )
}

export default Menu2Icon
