import { Box } from '@chakra-ui/react'

import ScrollToTop from "react-scroll-to-top";
import ArrowIcon from './icons/Arrow1';

const GoToTop = () => {
  return (
    <Box>
      <ScrollToTop
        smooth
        style={{
          position: 'relative',
          borderRadius: '50%',
          background: 'rgba(0, 0, 0, 0.3)',
          right: 0,
          bottom: 0,
          width: '3rem',
          height: '3rem'
        }}
        component={
          <ArrowIcon
            boxSize='1.5rem'
            color='#fff'
            opacity='0.5'
            transition='.5s'
            _hover={{
              opacity: 1
            }}
          />
        }
      />
    </Box>
  )
}

export default GoToTop