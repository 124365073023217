import { Icon } from '@chakra-ui/react';

const SkypeIcon = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" {...props}>
      <path d="M22.5292 13.8839C23.8297 6.44251 17.3389 0.0725288 10.021 1.44581C4.4425 -1.96678 -1.96112 4.53736 1.45824 10.0985C0.129018 17.5227 6.61793 23.9751 13.9962 22.5663C19.5852 25.9549 25.9687 19.4421 22.5292 13.8839ZM12.3679 19.5294C9.3195 19.5294 6.25568 18.1417 6.27101 15.7526C6.2758 15.032 6.81151 14.3707 7.5255 14.3707C9.32047 14.3707 9.30322 17.0493 12.1609 17.0493C14.1667 17.0493 14.851 15.951 14.851 15.1863C14.851 12.4205 6.18476 14.1158 6.18476 8.91597C6.18476 6.10039 8.4867 4.16074 12.1063 4.37253C15.5583 4.57665 17.5813 6.10039 17.7768 7.52442C17.8736 8.45502 17.2574 9.17945 16.1869 9.17945C14.6268 9.17945 14.4667 7.08938 11.7786 7.08938C10.5653 7.08938 9.5418 7.59537 9.5418 8.6936C9.5418 10.9888 18.1573 9.65675 18.1573 14.7081C18.1563 17.6204 15.8352 19.5294 12.3679 19.5294Z" fill="currentColor" />
    </Icon>
  )
}

export default SkypeIcon
