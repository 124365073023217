import { Icon, Box } from '@chakra-ui/react';

const CardIcon = (props) => {
  return (
    <Box>
      <Icon
        viewBox="0 0 756 87"
        fill="none"
        {...props}
        transition={'all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.25s'}
      >
        <path d="M0.960007 43.76C0.960007 35.44 2.76001 28.04 6.36001 21.56C9.96001 15 14.96 9.92 21.36 6.32C27.84 2.64 35.16 0.799995 43.32 0.799995C53.32 0.799995 61.88 3.43999 69 8.71999C76.12 14 80.88 21.2 83.28 30.32H60.72C59.04 26.8 56.64 24.12 53.52 22.28C50.48 20.44 47 19.52 43.08 19.52C36.76 19.52 31.64 21.72 27.72 26.12C23.8 30.52 21.84 36.4 21.84 43.76C21.84 51.12 23.8 57 27.72 61.4C31.64 65.8 36.76 68 43.08 68C47 68 50.48 67.08 53.52 65.24C56.64 63.4 59.04 60.72 60.72 57.2H83.28C80.88 66.32 76.12 73.52 69 78.8C61.88 84 53.32 86.6 43.32 86.6C35.16 86.6 27.84 84.8 21.36 81.2C14.96 77.52 9.96001 72.44 6.36001 65.96C2.76001 59.48 0.960007 52.08 0.960007 43.76Z"
          fill="white"
        />
        <path d="M168.806 1.76V86H148.286V51.32H116.366V86H95.8463V1.76H116.366V34.76H148.286V1.76H168.806Z"
          fill="white"
        />
        <path d="M204.14 18.2V35.24H231.62V51.08H204.14V69.56H235.22V86H183.62V1.76H235.22V18.2H204.14Z"
          fill="white"
        />
        <path d="M279.022 86.84C272.862 86.84 267.342 85.84 262.462 83.84C257.582 81.84 253.662 78.88 250.702 74.96C247.822 71.04 246.302 66.32 246.142 60.8H267.982C268.302 63.92 269.382 66.32 271.222 68C273.062 69.6 275.462 70.4 278.422 70.4C281.462 70.4 283.862 69.72 285.622 68.36C287.382 66.92 288.262 64.96 288.262 62.48C288.262 60.4 287.542 58.68 286.102 57.32C284.742 55.96 283.022 54.84 280.942 53.96C278.942 53.08 276.062 52.08 272.302 50.96C266.862 49.28 262.422 47.6 258.982 45.92C255.542 44.24 252.582 41.76 250.102 38.48C247.622 35.2 246.382 30.92 246.382 25.64C246.382 17.8 249.222 11.68 254.902 7.28C260.582 2.8 267.982 0.559998 277.102 0.559998C286.382 0.559998 293.862 2.8 299.542 7.28C305.222 11.68 308.262 17.84 308.662 25.76H286.462C286.302 23.04 285.302 20.92 283.462 19.4C281.622 17.8 279.262 17 276.382 17C273.902 17 271.902 17.68 270.382 19.04C268.862 20.32 268.102 22.2 268.102 24.68C268.102 27.4 269.382 29.52 271.942 31.04C274.502 32.56 278.502 34.2 283.942 35.96C289.382 37.8 293.782 39.56 297.142 41.24C300.582 42.92 303.542 45.36 306.022 48.56C308.502 51.76 309.742 55.88 309.742 60.92C309.742 65.72 308.502 70.08 306.022 74C303.622 77.92 300.102 81.04 295.462 83.36C290.822 85.68 285.342 86.84 279.022 86.84Z"
          fill="white"
        />
        <path d="M352.85 86.84C346.69 86.84 341.17 85.84 336.29 83.84C331.41 81.84 327.49 78.88 324.53 74.96C321.65 71.04 320.13 66.32 319.97 60.8H341.81C342.13 63.92 343.21 66.32 345.05 68C346.89 69.6 349.29 70.4 352.25 70.4C355.29 70.4 357.69 69.72 359.45 68.36C361.21 66.92 362.09 64.96 362.09 62.48C362.09 60.4 361.37 58.68 359.93 57.32C358.57 55.96 356.85 54.84 354.77 53.96C352.77 53.08 349.89 52.08 346.13 50.96C340.69 49.28 336.25 47.6 332.81 45.92C329.37 44.24 326.41 41.76 323.93 38.48C321.45 35.2 320.21 30.92 320.21 25.64C320.21 17.8 323.05 11.68 328.73 7.28C334.41 2.8 341.81 0.559998 350.93 0.559998C360.21 0.559998 367.69 2.8 373.37 7.28C379.05 11.68 382.09 17.84 382.49 25.76H360.29C360.13 23.04 359.13 20.92 357.29 19.4C355.45 17.8 353.09 17 350.21 17C347.73 17 345.73 17.68 344.21 19.04C342.69 20.32 341.93 22.2 341.93 24.68C341.93 27.4 343.21 29.52 345.77 31.04C348.33 32.56 352.33 34.2 357.77 35.96C363.21 37.8 367.61 39.56 370.97 41.24C374.41 42.92 377.37 45.36 379.85 48.56C382.33 51.76 383.57 55.88 383.57 60.92C383.57 65.72 382.33 70.08 379.85 74C377.45 77.92 373.93 81.04 369.29 83.36C364.65 85.68 359.17 86.84 352.85 86.84Z"
          fill="white"
        />
        <path d="M418.148 43.76C418.148 35.44 419.948 28.04 423.548 21.56C427.148 15 432.148 9.92 438.548 6.32C445.028 2.64 452.348 0.799995 460.508 0.799995C470.508 0.799995 479.068 3.43999 486.188 8.71999C493.308 14 498.068 21.2 500.468 30.32H477.908C476.228 26.8 473.828 24.12 470.708 22.28C467.668 20.44 464.188 19.52 460.268 19.52C453.948 19.52 448.828 21.72 444.908 26.12C440.988 30.52 439.028 36.4 439.028 43.76C439.028 51.12 440.988 57 444.908 61.4C448.828 65.8 453.948 68 460.268 68C464.188 68 467.668 67.08 470.708 65.24C473.828 63.4 476.228 60.72 477.908 57.2H500.468C498.068 66.32 493.308 73.52 486.188 78.8C479.068 84 470.508 86.6 460.508 86.6C452.348 86.6 445.028 84.8 438.548 81.2C432.148 77.52 427.148 72.44 423.548 65.96C419.948 59.48 418.148 52.08 418.148 43.76Z"
          fill="white"
        />
        <path d="M565.474 71.12H534.034L528.994 86H507.514L537.994 1.76H561.754L592.234 86H570.514L565.474 71.12ZM560.194 55.28L549.754 24.44L539.434 55.28H560.194Z"
          fill="white"
        />
        <path d="M644.47 86L626.95 54.2H622.03V86H601.51V1.76H635.95C642.59 1.76 648.23 2.92 652.87 5.23999C657.59 7.55999 661.11 10.76 663.43 14.84C665.75 18.84 666.91 23.32 666.91 28.28C666.91 33.88 665.31 38.88 662.11 43.28C658.99 47.68 654.35 50.8 648.19 52.64L667.63 86H644.47ZM622.03 39.68H634.75C638.51 39.68 641.31 38.76 643.15 36.92C645.07 35.08 646.03 32.48 646.03 29.12C646.03 25.92 645.07 23.4 643.15 21.56C641.31 19.72 638.51 18.8 634.75 18.8H622.03V39.68Z"
          fill="white"
        />
        <path d="M711.352 1.76C720.232 1.76 727.992 3.52 734.632 7.03999C741.272 10.56 746.392 15.52 749.992 21.92C753.672 28.24 755.512 35.56 755.512 43.88C755.512 52.12 753.672 59.44 749.992 65.84C746.392 72.24 741.232 77.2 734.512 80.72C727.872 84.24 720.152 86 711.352 86H679.792V1.76H711.352ZM710.032 68.24C717.792 68.24 723.832 66.12 728.152 61.88C732.472 57.64 734.632 51.64 734.632 43.88C734.632 36.12 732.472 30.08 728.152 25.76C723.832 21.44 717.792 19.28 710.032 19.28H700.312V68.24H710.032Z"
          fill="white"
        />

      </Icon>
    </Box>
  )
}

export default CardIcon