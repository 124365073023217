import { Icon } from '@chakra-ui/react';
const ArrowIcon = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" {...props}>
      <path d="M0.360401 16.271L11.2054 5.33117C11.6431 4.88961 12.3569 4.88961 12.7946 5.33117L23.6396 16.271C24.1201 16.7557 24.1201 17.5372 23.6396 18.022C23.153 18.5127 22.3597 18.5127 21.8732 18.022L11.9999 8.06233L2.12674 18.022C1.64023 18.5127 0.846912 18.5127 0.360402 18.022C-0.120133 17.5372 -0.120134 16.7557 0.360401 16.271Z" fill="currentColor" />
    </Icon>
  )
}

export default ArrowIcon
