import axios from 'axios'
import { toast } from 'react-toastify';
// Redux
import { switchLoader } from '../store/slice/site';

const service = axios.create({
  baseURL: 'https://bo.hello.laiplay.win',
  // baseURL: 'http://localhost:8000',
  timeout: 500000
})

const errorHandler = (error) => {
  if (!error) return

  if (error?.response?.status) {
    console.error('[❌發生錯誤] ', error?.response?.data?.message)

    switch (error?.response?.status) {
      case 500:
        toast.error(`${error.response.status} Server error 伺服器发生错误`, {
          position: "top-center",
        });
        break
      default:
        toast.error(`${error.response.status} 發生錯誤`, {
          position: "top-center",
        });
        break
    }
  } else {
    toast.error(`發生錯誤`, {
      position: "top-center",
    });
  }

  console.error('[⚠️錯誤物件]', error)

  return Promise.reject({ ...error })
}

const responseHandle = (res) => {
  if (!res) return

  console.log('res', res)
  switch (res?.status) {
    case 0:
      toast.error(`错误码：${res.status}，发生错误：${res.message} ${res.data}`, {
        position: "top-center",
      });
      break
    case 1:
      break
    case 9201:
      toast.error(`错误码：${res.status}，发生错误：${res.message} ${res.data.ip[0]}`, {
        position: "top-center",
      });
      break
    default:
      toast.error(`错误码：${res.status} 发生错误：${res.message}`, {
        position: "top-center",
      });
      break
  }
}

export const interceptor = store => {
  service.interceptors.request.use(
    config => {
      // 顯示 Preloader
      store.dispatch(switchLoader(true))
      // console.error('[Loader] ', store.getState().site.loader)

      console.log(
        `%c🔌 Request:%c${config.url}`,
        'background:bisque; padding: 3px; border-radius: 5px; color: #7e7e7e;',
        'padding: 3px;',
        config.data
      )

      return config
    },

    error => {
      console.error('[❌發生錯誤]', error) // for debug
      return Promise.reject(error)
    }
  )

  service.interceptors.response.use(
    response => {
      // 顯示 Preloader
      setTimeout(() => {
        store.dispatch(switchLoader(false))
      }, 1000)

      // console.log(
      //   `%c🔌 Response:%c${response?.config?.url}`,
      //   'background:green; padding: 3px; border-radius: 5px; color: #fff;',
      //   'padding: 3px;',
      //   response.data
      // )
      console.log(
        `%c🤖 Res Data: %c${response?.config?.url}`,
        'background:deepskyblue; padding: 3px; border-radius: 5px; color: #fff;',
        'padding: 3px;',
        response?.data?.data
      )

      const res = response.data
      responseHandle(res)

      return res
    },

    error => {
      store.dispatch(switchLoader(false))
      errorHandler(error)
    }
  )
}

export default service
