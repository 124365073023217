import { Icon } from '@chakra-ui/react';
const MenuIcon = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" {...props}>
      <path d="M0 18C0 19.1046 0.895432 20 2 20H22C23.1046 20 24 19.1046 24 18C24 16.8954 23.1046 16 22 16H2C0.895432 16 0 16.8954 0 18Z" fill="currentColor" />
      <path d="M0 7C0 8.10457 0.895432 9 2 9H22C23.1046 9 24 8.10457 24 7C24 5.89543 23.1046 5 22 5H2C0.895432 5 0 5.89543 0 7Z" fill="currentColor" />
    </Icon>
  )
}

export default MenuIcon