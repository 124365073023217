// Chakra UI
import { Box, Image, Link } from '@chakra-ui/react'

const PinnedIcon = ({ data }) => {

  const position = {
    'bottom-right': {
      position: 'fixed',
      right: '1rem',
      bottom: '230px'
    }
  }

  return (
    <Box
      className={'pinned-icon'}
      pos='fixed'
      bottom={position[data?.pinned_icon.position]?.bottom}
      right={position[data?.pinned_icon.position]?.right}
      left={position[data?.pinned_icon.position]?.left}
      top={position[data?.pinned_icon.position]?.top}
      transform={position[data?.pinned_icon.position]?.transform}
      zIndex={50}
    >
      <Link
        isExternal
        scroll="false"
        href='https://games.laiplay.win/'
      >
        <Image
          width={{ base: '130px', smd: '130px', mmd: '150px', md: '180px' }}
          height={{ base: '140px', smd: '140px', mmd: '160px', md: '204px' }}
          // width='180px'
          // height='204px'
          alt={data?.pinned_icon.position}
          src={`${data?.image_url}/${data?.pinned_icon.img}`}
        />
      </Link>
    </Box>
  )
}

export default PinnedIcon;