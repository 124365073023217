
// React
import { useRouter } from 'next/router'
// Next
import NextLink from 'next/link'
// Chakra UI
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Flex,
  Image,
  Box,
  Text,
  Button,
} from '@chakra-ui/react'
import { useDisclosure } from '@chakra-ui/react'
// Redux
import { useDispatch, useSelector } from 'react-redux'
import { selectSite, addType, addActiveVendor, addVendor, addLanguage } from '../store/slice/site'
// Icon
import PersonIcon from '../components/icons/Person'
import MenuIcon from '../components/icons/Menu2'
import SlotIcon from '../components/icons/Slot'
import CardIcon from '../components/icons/Card'
import FishIcon from '../components/icons/Fish'
import SportIcon from '../components/icons/Sport'
import EsportIcon from '../components/icons/Esport'
import LiveIcon from '../components/icons/Live'
import LotteryIcon from '../components/icons/Lottery'
// Components
import ModalAccount from './ModalAccount'


const ModalMenu = ({ isOpen, onOpen, onClose, data }) => {
  const router = useRouter()
  const state = useSelector(selectSite)
  const dispatch = useDispatch()

  // 開啟切換帳號燈箱
  const { isOpen: accountIsOpen, onOpen: accountOnOpen, onClose: accountOnClose } = useDisclosure()

  // 切換 Vendor
  const switchVendor = (type, vendorCode, vendors) => {
    const activeVendor = vendors.filter(vendor => vendor.code === vendorCode)

    // 更新 Redux 網址列 type
    dispatch(addType(type))

    // 更新 Reduxs vendors
    const temp = data?.menus.find(item => {
      return item.vendors.find(vendor => vendor.code === vendorCode)
    })
    dispatch(addVendor(temp.vendors))

    // 更新 Redux 目前的 Vendor
    dispatch(addActiveVendor(activeVendor[0]))

    onClose()

    // 加上 scroll: false，換頁時才不會回到 scroll to top
    router.push({
      pathname: `/${type}/${vendorCode}`
    }, undefined, { scroll: false })
  }

  /*--- 切換語系 ---*/
  const handleLanguage = (val) => {
    dispatch(addLanguage(val))
  }

  return (
    <>
      <Modal onClose={onClose} size='full' isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent background="#2B2B2B" color='#fff'>
          <ModalHeader>
            <Flex justify='flex-end' gap='1rem'>
              <PersonIcon
                boxSize='1.9rem'
                color='#fff'
                cursor='pointer'
                onClick={accountOnOpen}
              />
              <MenuIcon
                boxSize='1.9rem'
                color='#fff'
                onClick={onClose}
                cursor='pointer'
              />
            </Flex>
          </ModalHeader>
          <ModalBody p='3.5rem'>
            <Flex align='center' justify='center' mb='3.5rem'>
              <Image
                src={data?.image_url + '/' + data?.setting?.logo_light}
                alt='logo'
                width='170px'
              />
            </Flex>

            <Accordion allowToggle width='100%'>
              {
                data?.menus && data?.menus?.length > 0
                && data?.menus?.map((item, i) => {
                  return (
                    <AccordionItem
                      key={'rwd-menu-' + item.code + i} border='0'>
                      <h2>
                        <AccordionButton height='3.5rem'>
                          <Box flex='1' textAlign='left'>
                            <Flex align='center'>
                              <Box mr='1rem'>
                                <SlotIcon
                                  display={item.code === 'slot' ? 'block' : 'none'}
                                  boxSize='1.5rem'
                                  color='#fff'
                                />
                                <CardIcon
                                  display={item.code === 'card' ? 'block' : 'none'}
                                  boxSize='1.5rem'
                                  color='#fff'
                                />
                                <FishIcon
                                  display={item.code === 'fish' ? 'block' : 'none'}
                                  boxSize='1.5rem'
                                  color='#fff'
                                />
                                <SportIcon
                                  display={item.code === 'sport' ? 'block' : 'none'}
                                  boxSize='1.5rem'
                                  color='#fff'
                                />
                                <EsportIcon
                                  display={item.code === 'esport' ? 'block' : 'none'}
                                  boxSize='1.5rem'
                                  color='#fff'
                                />
                                <LiveIcon
                                  display={item.code === 'live' ? 'block' : 'none'}
                                  boxSize='1.5rem'
                                  color='#fff'
                                />
                                <LotteryIcon
                                  display={item.code === 'lottery' ? 'block' : 'none'}
                                  boxSize='1.5rem'
                                  color='#fff'
                                />
                              </Box>
                              <Text>
                                {state.language === 'zh' ? item.name_zh : item.name_en}
                              </Text>
                            </Flex>
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>

                      <AccordionPanel pt='2rem' px='3.5rem'>
                        {
                          item.vendors?.length > 0 && item.vendors.map((vendor, j) => {
                            return (
                              <Box mb='1.3rem'
                                key={'rwd-vendor-' + vendor.code + j}>
                                <Box
                                  cursor='pointer'
                                  onClick={() => switchVendor(item.code, vendor.code, item.vendors)}
                                  color='#979797'
                                  _hover={{ color: '#fff' }}
                                >
                                  {state.language === 'zh' ? vendor.name_zh : vendor.name_en}
                                </Box>
                              </Box>
                            )
                          })
                        }
                      </AccordionPanel>
                    </AccordionItem>
                  )
                })
              }
            </Accordion>

            <Flex pr='4' w={'100%'} mt={'40px'} justifyContent={'center'}>
              <Button
                background={state.language === 'zh' ? '#fff' : '#2B2B2B'}
                color={state.language === 'zh' ? '#2B2B2B' : '#fff'}
                borderRadius='50px'
                size='sm'
                mr='.5rem'
                onClick={() => handleLanguage('zh')}
                className={'wow fadeInUp'}
                data-wow-duration='1s'
                data-wow-delay="0.5s"
              >
                簡中
              </Button>
              <Button
                background={state.language === 'en' ? '#fff' : '#2B2B2B'}
                color={state.language === 'en' ? '#2B2B2B' : '#fff'}
                borderRadius='50px'
                size='sm'
                onClick={() => handleLanguage('en')}
                className={'wow fadeInUp'}
                data-wow-duration='1s'
                data-wow-delay="0.5s"
              >
                English
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal >
      <ModalAccount isOpen={accountIsOpen} onOpen={accountOnOpen} onClose={accountOnClose} />
    </>
  )
}

export default ModalMenu