// React
import { useEffect, useState } from 'react';
import { Box, Flex, Show } from '@chakra-ui/react'
// import Image from 'next/image'
// import Image from 'next/future/image'
// Redux
import { useDispatch, useSelector } from 'react-redux';
import {
  selectSite,
  addType,
  addTheme,
  addVendor,
} from '../store/slice/site';
import { useInView } from 'react-intersection-observer';

// Components
import Navbar from './Navbar'
import Footer from './Footer'
import Cs from '../components/Cs'
import GoToTop from './GoToTop';
import PinnedIcon from '../components/PinnedIcon'
import SlotIcon from '../components/icons/Slot'
import CardIcon from '../components/icons/Card'
import FishIcon from '../components/icons/Fish'
import SportIcon from '../components/icons/Sport'
import EsportIcon from '../components/icons/Esport'
import LiveIcon from '../components/icons/Live'
import LotteryIcon from '../components/icons/Lottery'
// Banner
import SlotTextIcon from '../components/icons/Banner/Slot'
import CardTextIcon from '../components/icons/Banner/Card'
import FishTextIcon from '../components/icons/Banner/Fish'
import SportsTextIcon from '../components/icons/Banner/Sports'
import EsportsTextIcon from '../components/icons/Banner/Esports'
import LiveTextIcon from '../components/icons/Banner/Live'
import LotteryTextIcon from '../components/icons/Banner/Lottery'

// SubTitle
import GamesTextIcon from '../components/icons/SubTitle/Games'
import EventsTextIcon from '../components/icons/SubTitle/Events'
import CasinoTextIcon from '../components/icons/SubTitle/Casino'
// API
import { useRouter } from 'next/router'
// React Query
import { useSiteInfoData } from '../hooks/useSiteInfoData'
import { useGameData } from '../hooks/useGameData';
import { gameType } from '../utils/data'
// CSS
import styles from '../styles/Layout.module.scss'

const Layout = ({ children }) => {
  const state = useSelector(selectSite)
  const dispatch = useDispatch()
  const router = useRouter()
  const { ref, inView } = useInView()

  const [banner, setBanner] = useState({
    bg: '',
    element: '',
    dot: '#fff',
    text1: '',
    text2: ''
  })

  // 取得站台資料：成功動作
  const onSuccess = (res) => {
    if (!res?.data) return
    // 處理主題
    dispatch(addTheme(res?.data?.setting?.theme))

    // 處理預設網址
    handleRouter(
      res?.data?.menus[0]?.code,
      res?.data?.menus[0]?.vendors[0]?.code,
      router
    )
  }

  // 取得站台資料
  const {
    data: siteData,
    isLoading: siteIsLoading,
    isFetching: siteIsFetching,
    error: siteError
  } = useSiteInfoData(onSuccess)

  // 取得遊戲清單
  const {
    fetchNextPage,
    hasNextPage
  } = useGameData(state.searchGame)

  // 根據類型改變 Banner Icon
  const renderIcon = () => {
    const { type } = router?.query
    const boxStyle = {
      display: (type) => (state.type === type ? 'block' : 'none'),
      boxSize: { base: '1.2rem', smd: '1.4rem', mmd: '1.5rem', md: '2rem', lg: '3.5rem', xl: '4rem' },
    }

    switch (type) {
      case 'slot':
        return (
          <SlotIcon
            display={boxStyle.display('slot')}
            boxSize={boxStyle.boxSize}
            color='#2C3034'
          />
        )

      case 'card':
        return (
          <CardIcon
            display={boxStyle.display('card')}
            boxSize={boxStyle.boxSize}
            color='#fff'
          />
        )

      case 'fish':
        return (
          <FishIcon
            display={boxStyle.display('fish')}
            boxSize={boxStyle.boxSize}
            color='#fff'
          />
        )

      case 'sport':
        return (
          <SportIcon
            display={boxStyle.display('sport')}
            boxSize={boxStyle.boxSize}
            color='#fff'
          />
        )

      case 'esport':
        return (
          <EsportIcon
            display={boxStyle.display('esport')}
            boxSize={boxStyle.boxSize}
            color='#fff'
          />
        )

      case 'live':
        return (
          <LiveIcon
            display={boxStyle.display('live')}
            boxSize={boxStyle.boxSize}
            color='#2C3034'
          />
        )

      case 'lottery':
        return (
          <LotteryIcon
            display={boxStyle.display('lottery')}
            boxSize={boxStyle.boxSize}
            color='#2C3034'
          />
        )
    }
  }

  // 根據類型改變 Banner 文字
  const renderText = () => {
    const { type } = router?.query
    const textStyle = {
      height: { base: '1.5rem', smd: '2.5rem', mmd: '3.5rem', md: '4.5rem', lg: '5.5rem', xl: '6.5rem', '2xl': '7rem' },
      width: 'auto',
      mt: { base: '.5rem', mmd: '1rem', xl: '2rem' }
    }

    if (type === 'slot') {
      return (
        <>
          <SlotTextIcon
            height={textStyle.height}
            width={textStyle.width}
          />
          <GamesTextIcon
            height={textStyle.height}
            width={textStyle.width}
            mt={textStyle.mt}
          />
        </>
      )
    }

    if (type === 'card') {
      return (
        <>
          <CardTextIcon
            height={textStyle.height}
            width={textStyle.width}
          />
          <GamesTextIcon
            height={textStyle.height}
            width={textStyle.width}
            mt={textStyle.mt}
          />
        </>
      )
    }

    if (type === 'fish') {
      return (
        <>
          <FishTextIcon
            height={textStyle.height}
            width={textStyle.width}
          />
          <GamesTextIcon
            height={textStyle.height}
            width={textStyle.width}
            mt={textStyle.mt}
          />
        </>
      )
    }
    if (type === 'sport') {
      return (
        <>
          <SportsTextIcon
            height={textStyle.height}
            width={textStyle.width}
          />
          <EventsTextIcon
            height={textStyle.height}
            width={textStyle.width}
            mt={textStyle.mt}
          />
        </>
      )
    }
    if (type === 'esport') {
      return (
        <>
          <EsportsTextIcon
            height={textStyle.height}
            width={textStyle.width}
          />
        </>
      )
    }
    if (type === 'live') {
      return (
        <>
          <LiveTextIcon
            height={textStyle.height}
            width={textStyle.width}
          />
          <CasinoTextIcon
            height={textStyle.height}
            width={textStyle.width}
            mt={textStyle.mt}
          />
        </>
      )
    }
    if (type === 'lottery') {
      return (
        <>
          <LotteryTextIcon
            height={textStyle.height}
            width={textStyle.width}
          />
          <GamesTextIcon
            height={textStyle.height}
            width={textStyle.width}
            mt={textStyle.mt}
          />
        </>
      )
    }
  }

  // Banner 動畫
  function initScrollMagic() {

    if (typeof window !== 'undefined' || !window?.ScrollMagic) {
      window.ScrollMagic = require('scrollmagic')
      const controller = new ScrollMagic.Controller();

      var elements = {
        gameType: new ScrollMagic.Scene({
          triggerElement: '#gametype__trigger',
          triggerHook: 0.9,
          duration: "80%", // hide 10% before exiting view (80% + 10% from bottom)
        })
          .setClassToggle('#gametype__trigger', 'on--visible')
          .addTo(controller),
      }

    }
  }

  /*--- 產生 banner 兩排點點 ---*/
  let dots = []
  for (let i = 0; i < 9; i++) {
    dots.push(<Box
      key={i}
      w={{ base: '3px', sm: '3px', mmd: '4px', md: '5px', lg: '9.5px' }} h={{ base: '3px', sm: '3px', md: '5px', lg: '9.5px' }} bgColor={banner.dot} borderRadius='50%'
      mx={{ base: '0.15rem', sm: '0.15rem', md: '0.5rem', lg: '0.8rem' }}
    />)
  }

  /*--- 根據遊戲類型，更改點點的顏色 ---*/
  const handleDotColor = (type) => {
    if (type === 'slot' || type === 'live' || type === 'lottery') {
      return '#2C3034'
    }
    return '#fff'
  }

  // 處理預設網址
  const handleRouter = (type, vendor, router) => {
    if (router.asPath === '/') {
      const href = `/${type}/${vendor}`
      router.push(href)
    }
  }

  // create a scene
  useEffect(() => {
    initScrollMagic()
  })

  /*--- 監聽換頁 ---*/
  useEffect(() => {
    let gameType = document.getElementById('gametype__trigger');

    router?.events.on('routeChangeComplete', () => {
      // 換頁重啟動畫
      if (gameType) gameType.classList?.remove('on--visible')

      setTimeout(() => {
        if (gameType) gameType.classList.add('on--visible')
      }, 1000)
    })
  })

  // 若 router 有參數，則寫入 redux
  useEffect(() => {
    if (router?.query.type) dispatch(addType(router?.query.type))
  }, [dispatch, router?.query.type, state.type])

  /*--- 監聽切換遊戲類型，隨之變換 Banner ---*/
  useEffect(() => {
    let temp = siteData?.data?.menus.find(item => item.code === state.type)

    setBanner({
      bg: siteData?.data?.image_url + '/' + temp?.banner,
      // element: '',
      element: 'http://bo.hello.goldenf.co/uploads/' + temp?.element_1,
      // element: siteData?.data?.image_url + '/' + temp?.element_1,
      dot: handleDotColor(state.type),
      text1: gameType[state.type]?.text1,
      text2: gameType[state.type]?.text2,
    })
  }, [siteData?.data?.image_url, siteData?.data?.menus, state])

  // console.error(banner.bg, '[banner bg]')
  // console.error(banner.element, '[banner Element]')

  /*--- 監聽是否到看到 footer ---*/
  useEffect(() => {
    // 看到 footer，且還有下一頁時，觸發 fetchNextPage()
    if (inView && hasNextPage) {
      fetchNextPage()
    }
  }, [fetchNextPage, hasNextPage, inView])

  // 需判斷 Router 準備好了沒有
  useEffect(() => {
    if (!router?.isReady || !siteData) {
      // console.log('Router Not Ready')
      return
    }

    const currentType = router?.query?.type
      ? router?.query?.type // 若網址已有遊戲類型，則沿用
      : siteData?.data?.menus[0].code // 處理 Type，將排序第一個類型當作預設

    dispatch(addType(currentType))

    const hasVendor = router?.query?.type

    if (hasVendor) { // 若網址已有遊戲類型，則沿用
      const currentVendor = siteData?.data?.menus?.filter(item => item.code === hasVendor)
      // console.log(currentVendor)
      currentVendor?.length === 1 && dispatch(addVendor(currentVendor[0]?.vendors))
    } else if (siteData?.menus?.length > 0) {
      // 將第一個類型的產品商們當預設
      dispatch(addVendor(siteData?.menus[0].vendors))
    }
  }, [
    siteData,
    router?.query,
    siteData?.menus,
    dispatch,
    router?.isReady
  ])

  return (
    <>
      <Box pos='relative'>
        <Box
          bgImage={`url(${banner.bg})`}
          bgRepeat="no-repeat"
          bgSize={{ base: 'cover', '2xl': '100% 100%', '3xl': 'cover' }}
          bgPosition='center'
          h={{ base: '236px', smd: '300px', mmd: '400px', md: '496px', lg: '650px', xl: '750px', '2xl': '864px' }}
          overflow='hidden'
          position='relative'
        >
          <Navbar data={siteData?.data} error={siteError} />
          <Box
            mt={{ base: '1rem', smd: '2rem', md: '5rem', lg: '6rem' }}
            ml={{ base: '1rem', md: '1.5rem', lg: '2.5rem', xl: '6.5rem' }}
            position='relative'
            zIndex='2'
          >
            {renderIcon()}
            <Box
              mt={{ base: '.5rem', mmd: '1rem', xl: '1.5rem', '2xl': '2rem' }}
              mb={{ base: '1rem', mmd: '2rem', xl: '3rem', '2xl': '5rem' }}
              id='gametype__trigger'
              flex
              alignItems={'flex-start'}
            >
              {renderText()}
            </Box>
            <Flex>
              {dots}
            </Flex>
            <Flex mt={{ base: '0.3rem', lg: '1rem' }} ml='1rem'>
              {dots}
            </Flex>
          </Box>

          {/* 元素 */}
          <Box
            position='absolute'
            right={{ base: '0rem', md: '-3rem', lg: '-4rem', xl: '0rem' }}
            bottom='0rem'
            pointerEvents='none'
            className={'wow fadeInUp layout-element'}
            data-wow-delay="0.5s"
            id={'element__image'}
            w='100%'
            h='100%'
          >
            <Box
              className={styles.box}
              w={{
                base: '375px',
                smd: '500px',
                mmd: '650px',
                md: '880px',
                lg: '100%',
                xl: '100%'
              }}
              h='100%'
              position='relative'
            >
              <Box
                id='element-pic'
                bgImage={banner?.element}
                w={'100%'}
                h={'100%'}
                bgRepeat={'no-repeat'}
                bgPosition={'center'}
                bgSize={'contain'}
              >
              </Box>
            </Box>
          </Box>
        </Box>

        <Box mt={{ base: '.5rem', md: '3rem' }}>
          {children}
        </Box>

        <Box mt='2.5rem' ref={ref}>
          <Footer data={siteData?.data} />
        </Box>

        <Box
          position='fixed'
          right='1rem'
          bottom='5%'
          zIndex='999'
          translateY={{ base: '-50%' }}
        >
          <Flex direction='column' align='flex-end' gap={3}>
            <Cs data={siteData?.data} isLoading={siteIsLoading} isFetching={siteIsFetching} />
            <GoToTop />
          </Flex>
        </Box>
        <Show>
          <PinnedIcon data={siteData?.data} pos='absolute' />
        </Show>
      </Box>
    </>
  )
}

export default Layout