import { Icon, Box } from '@chakra-ui/react';

const LiveIcon = (props) => {
  return (
    <Box>
      <Icon
        viewBox="0 0 233 85"
        fill="none"
        {...props}
        transition={'all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.25s'}
      >
        <path d="M20.96 69.16H47.84V85H0.440002V0.759998H20.96V69.16Z" fill="white" />
        <path d="M78.1475 0.759998V85H57.6275V0.759998H78.1475Z" fill="white" />
        <path d="M172.098 0.759998L142.218 85H116.538L86.6581 0.759998H108.498L129.378 64.36L150.378 0.759998H172.098Z" fill="white" />
        <path d="M201.194 17.2V34.24H228.674V50.08H201.194V68.56H232.274V85H180.674V0.759998H232.274V17.2H201.194Z" fill="white" />

      </Icon>
    </Box>
  )
}

export default LiveIcon