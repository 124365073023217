import { Icon } from '@chakra-ui/react'

const SlotIcon = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M5.86739 21.1875C5.61824 21.1875 5.41602 21.3975 5.41602 21.6563C5.41602 21.915 5.61824 22.125 5.86739 22.125C6.11659 22.125 6.31882 21.915 6.31882 21.6563C6.31882 21.3975 6.11659 21.1875 5.86739 21.1875Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M4.4278 12.6634C4.34298 12.5411 4.20671 12.4688 4.06163 12.4688H2.25607C2.00678 12.4688 1.80469 12.6786 1.80469 12.9375C1.80469 13.1963 2.00678 13.4063 2.25607 13.4063H3.43538L2.73062 15.6018C2.65176 15.8474 2.7796 16.1128 3.01612 16.1947C3.06347 16.2111 3.11159 16.2189 3.15895 16.2189C3.34794 16.2189 3.52407 16.0946 3.58709 15.8982L4.48984 13.0857C4.53575 12.9428 4.51264 12.7856 4.4278 12.6634Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M9.84577 12.6634C9.76097 12.5411 9.62466 12.4688 9.47959 12.4688H7.67403C7.42475 12.4688 7.22266 12.6786 7.22266 12.9375C7.22266 13.1963 7.42475 13.4063 7.67403 13.4063H8.85332L8.14861 15.6018C8.06972 15.8474 8.19755 16.1128 8.43408 16.1947C8.48146 16.2111 8.52955 16.2189 8.57692 16.2189C8.7659 16.2189 8.94203 16.0946 9.00506 15.8982L9.90781 13.0857C9.95372 12.9428 9.93061 12.7856 9.84577 12.6634Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M15.2618 12.6634C15.177 12.5411 15.0407 12.4688 14.8956 12.4688H13.09C12.8407 12.4688 12.6387 12.6786 12.6387 12.9375C12.6387 13.1963 12.8407 13.4063 13.09 13.4063H14.2693L13.5645 15.6018C13.4857 15.8474 13.6135 16.1128 13.8501 16.1947C13.8974 16.2111 13.9456 16.2189 13.9929 16.2189C14.1819 16.2189 14.358 16.0946 14.4211 15.8982L15.3238 13.0857C15.3697 12.9428 15.3466 12.7856 15.2618 12.6634Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M11.2851 21.1875H7.67403C7.42475 21.1875 7.22266 21.3974 7.22266 21.6563C7.22266 21.9151 7.42475 22.125 7.67403 22.125H11.2851C11.5345 22.125 11.7365 21.9151 11.7365 21.6563C11.7365 21.3974 11.5345 21.1875 11.2851 21.1875Z" fill="currentColor" />
      <mask id="mask0_7715_69407" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <path fillRule="evenodd" clipRule="evenodd" d="M0 0H23.1111V24H0V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_7715_69407)">
        <path fillRule="evenodd" clipRule="evenodd" d="M21.5313 11.3432C21.3261 11.4662 21.092 11.5312 20.8542 11.5312C20.6164 11.5312 20.3822 11.4662 20.177 11.3432C19.7594 11.0921 19.5 10.6253 19.5 10.125C19.5 9.3496 20.1075 8.71876 20.8542 8.71876C21.6008 8.71876 22.2084 9.3496 22.2084 10.125C22.2084 10.6255 21.9488 11.0924 21.5313 11.3432ZM21.3056 13.4063H20.4028V12.4211C20.5508 12.4525 20.702 12.4688 20.8542 12.4688C21.0063 12.4688 21.1575 12.4525 21.3056 12.4211V13.4063ZM21.3056 18.8437C21.3056 19.4548 20.9281 19.9759 20.4028 20.1694V17.7656V14.3437H21.3056V18.8437ZM17.1528 20.25H19.5V18.2344H17.1528V20.25ZM16.25 10.4531H0.902778V8.90627C0.902778 4.51227 4.34516 0.937498 8.5764 0.937498C12.8076 0.937498 16.25 4.51227 16.25 8.90627V10.4531ZM11.7361 17.2969H16.25V11.3906H11.7361V17.2969ZM6.31947 17.2969H10.8333V11.3906H6.31947V17.2969ZM16.25 20.7188V22.5937C16.25 22.8522 16.0475 23.0625 15.7986 23.0625H13.5417V19.7812C13.5417 19.5224 13.3396 19.3125 13.0903 19.3125H4.0625C3.8132 19.3125 3.61111 19.5224 3.61111 19.7812V23.0625H1.35417C1.10527 23.0625 0.902778 22.8522 0.902778 22.5937V18.2344H5.86804H11.2847H16.25V20.7188ZM4.51387 23.0625H12.6389V20.25H4.51387V23.0625ZM0.902778 17.2969H5.41667V11.3906H0.902778V17.2969ZM20.8542 7.78124C19.6097 7.78124 18.5972 8.83267 18.5972 10.125C18.5972 10.8647 18.9377 11.5596 19.5 11.9991V17.2969H17.1528V10.9219V8.90627C17.1528 3.9953 13.3054 0 8.5764 0C3.84737 0 0 3.9953 0 8.90627V10.9219V22.5937C0 23.3692 0.60748 24 1.35417 24H4.0625H13.0903H15.7986C16.5453 24 17.1528 23.3692 17.1528 22.5937V21.1875H19.9514C21.1959 21.1875 22.2084 20.1361 22.2084 18.8437V11.9991C22.7706 11.5596 23.1111 10.8647 23.1111 10.125C23.1111 8.83267 22.0987 7.78124 20.8542 7.78124Z" fill="currentColor" />
        <path fillRule="evenodd" clipRule="evenodd" d="M6.76998 8.43751H2.72467C2.95591 5.29556 5.49171 2.8125 8.57553 2.8125C11.6593 2.8125 14.1951 5.2956 14.4264 8.43751H6.76998ZM8.57553 1.875C4.84207 1.875 1.80469 5.0292 1.80469 8.90627C1.80469 9.16511 2.00678 9.37502 2.25607 9.37502H6.76998H14.895C15.1443 9.37502 15.3463 9.16511 15.3463 8.90627C15.3463 5.0292 12.309 1.875 8.57553 1.875Z" fill="currentColor" />
        <path fillRule="evenodd" clipRule="evenodd" d="M8.57642 3.75C8.32709 3.75 8.125 3.95991 8.125 4.21875V6.09373C8.125 6.35258 8.32709 6.56249 8.57642 6.56249C8.82571 6.56249 9.0278 6.35258 9.0278 6.09373V4.21875C9.0278 3.95991 8.82571 3.75 8.57642 3.75Z" fill="currentColor" />
        <path fillRule="evenodd" clipRule="evenodd" d="M6.9807 6.58591L5.70417 5.26026C5.5279 5.07733 5.24208 5.07729 5.06581 5.26026C4.88954 5.44333 4.8895 5.74013 5.06581 5.92324L6.34234 7.24884C6.43048 7.34035 6.54599 7.38613 6.6615 7.38613C6.77701 7.38613 6.89257 7.34035 6.9807 7.24884C7.15697 7.06582 7.15701 6.76897 6.9807 6.58591Z" fill="currentColor" />
        <path fillRule="evenodd" clipRule="evenodd" d="M12.0862 5.26031C11.9099 5.07729 11.6241 5.07729 11.4478 5.26031L10.1713 6.58591C9.995 6.76898 9.995 7.06578 10.1713 7.24889C10.2594 7.3404 10.3749 7.38618 10.4904 7.38618C10.6059 7.38618 10.7215 7.34036 10.8096 7.24889L12.0862 5.92325C12.2624 5.74018 12.2624 5.44338 12.0862 5.26031Z" fill="currentColor" />
      </g>
    </Icon>
  )
}

export default SlotIcon
