import request from './request'

// 取得站台資料
export async function getSiteInfoAPI() {
  return request({
    url: 'api/v1/siteinfo',
    method: 'post',
  })
  // const response = await request({
  //   url: 'api/v1/siteinfo',
  //   method: 'post',
  // })
  // return response
}

// 取得遊戲清單
export function getGameListAPI(data) {
  return request({
    url: 'api/v1/games',
    method: 'post',
    data
  })
}

// 取得產品商清單
export function getVendorListAPI(data) {
  return request({
    url: 'api/v1/vendors',
    method: 'post',
    data
  })
}

// 啟動遊戲
export function launchGameAPI(data) {
  return request({
    url: 'api/v1/launch',
    method: 'post',
    data
  })
}
