import { useState } from 'react'
import {
  Flex,
  Heading,
  Box,
  Skeleton,
} from '@chakra-ui/react'
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper";
SwiperCore.use([Autoplay])
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

const Footer = ({ data, isLoading, isFetching }) => {
  // const [isLargerThan1280] = useMediaQuery('(min-width: 1280px)')
  // const [isLargerThan320] = useMediaQuery('(min-width: 320px)')
  // const [isLargerThan576] = useMediaQuery('(min-width: 576px)')

  const [prevEl, setPrevEl] = useState(null)
  const [nextEl, setNextEl] = useState(null)

  return (
    <Flex
      direction="column" justify="center" align="center" px="40px" py="25px" bg="#222222"
      overflow={'hidden'}
      className='footer'
    >
      <Heading
        as='h2'
        size='md'
        fontWeight="400"
        color="#FFFFFF"
        mb='3rem'>
        Our Partners
      </Heading>

      <Box
        background={'radial-gradient(62.96% 62.96% at 50% 24.07%, #2C3034 0%, rgba(44, 48, 52, 0) 100%)'}
        mixBlendMode={'multiply'}
        maxW={'container.md'}
        h={'28px'}
        w={{ md: '100%' }}
      >

      </Box>

      <Box
        w={'100%'}
        h={'100%'}
        pos={'relative'}

      >
        {data?.footer?.partner?.items && (
          <Swiper
            spaceBetween={23}
            slidesPerView='auto'
            loop={true}
            // navigation={true}
            navigation={{ prevEl, nextEl }}
            modules={[Pagination, Navigation]}
          // autoplay={{
          //   delay: 2000,
          //   disableOnInteraction: false
          // }}
          // style={{
          // width: isLargerThan1280
          //   ? '70vw'
          //   : isLargerThan576
          //     ? '70vw'
          //     : '200px'
          // }}
          // onSlideChange={() => console.log('slide change')}
          // onSwiper={(swiper) => console.log(swiper)}
          >
            {data?.footer?.partner?.items
              && data?.footer?.partner?.items.map((item, i) => {
                return (
                  <SwiperSlide
                    key={i}
                    style={{
                      width: 50
                    }}
                  >
                    <Skeleton isLoaded={!isLoading || !isFetching} fadeDuration={4}>
                      <Box
                        className={`${item.css_class} logo`}
                        backgroundImage={data?.image_url + '/' + data?.footer?.partner?.image}
                        backgroundPosition={`${-50 * (item.sprite_sort - 1)}px  0`}
                        bgRepeat={'no-repeat'}
                        bgSize={'auto 50px'}
                        w={'50px'}
                        h={'50px'}
                      />
                    </Skeleton>
                  </SwiperSlide>
                )
              })}

          </Swiper>
        )}
        {/* <Box>
          <Box
            className='swiper-button-prev'
            style={{
              zIndex: 1,
              left: '55px'
            }}
            ref={(node) => setPrevEl(node)}
          >
          </Box>
          <Box
            className='swiper-button-next'
            style={{
              zIndex: 1,
              right: '55px'
            }}
            ref={(node) => setNextEl(node)}>
          </Box>
        </Box> */}
      </Box>

      <Heading as='h6' size='xs' fontWeight="400" color="#FFFFFF" mt='3.5rem'>
        Copyright © {new Date().getFullYear()} Golden F All rights reserved
      </Heading>
    </Flex >
  )
}

export default Footer;