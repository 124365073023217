import { useInfiniteQuery } from '@tanstack/react-query'
import { getGameListAPI } from '../utils/api';
import { useRouter } from 'next/router';

export const useGameData = (param) => {

  const router = useRouter()
  return useInfiniteQuery(
    // [queryKey] 'games', 產品商, 關鍵字
    ['games', router?.query?.vendor, param],

    async ({ pageParam = 1, queryKey }) => {
      // console.log('pageParam', pageParam, 'queryKey:', queryKey)

      // 打 API 用的參數
      const paramForApi = {
        filter: {
          vendor_code: queryKey[1]
        },
        page: pageParam,
        per_page: param ? 0 : 12
      }

      const isGameCode = param?.split('_').length > 1

      if (param) {
        if (isGameCode) {
          paramForApi.filter.game_code = param
        } else {
          paramForApi.filter.name = param
        }
      }

      // console.log('paramForApi:', paramForApi)

      // 打 API
      const { data } = await getGameListAPI(paramForApi)

      // API 回傳內容整理
      return {
        data: data?.data ?? [],
        hasNextPage: data?.to && data?.data?.length == 12 ? true : false
      }
    },

    {
      // refetchOnWindowFocus: false,
      // keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      // refetchOnMount: false,
      getNextPageParam: (lastPage, pages) => {
        // 如果還有下一頁的話
        return lastPage?.hasNextPage
          ? pages.length + 1
          : undefined
      }
    }
  )
}