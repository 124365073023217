import { Icon } from '@chakra-ui/react';

const TelegramIcon = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M18.384 21.3755C18.706 21.5988 19.1209 21.6547 19.4909 21.5176C19.8609 21.3795 20.133 21.0699 20.215 20.6947C21.0839 16.694 23.1919 6.56796 23.9828 2.9288C24.0429 2.65451 23.9429 2.36945 23.7228 2.18627C23.5029 2.00308 23.1979 1.95019 22.9259 2.04913C18.7329 3.56946 5.82025 8.31558 0.542382 10.2287C0.207391 10.3502 -0.0106075 10.6656 0.000398261 11.0114C0.0123958 11.3582 0.250393 11.659 0.593384 11.7598C2.96033 12.4534 6.06726 13.4182 6.06726 13.4182C6.06726 13.4182 7.51923 17.7138 8.27619 19.8983C8.37118 20.1726 8.5902 20.3881 8.87922 20.4626C9.16715 20.536 9.47521 20.4586 9.69018 20.2598C10.9062 19.1352 12.7861 17.3964 12.7861 17.3964C12.7861 17.3964 16.358 19.9619 18.384 21.3755ZM7.3742 12.8756L9.0532 18.3006L9.4262 14.8652C9.4262 14.8652 15.913 9.13352 19.611 5.86663C19.719 5.7706 19.734 5.60992 19.644 5.49729C19.5549 5.38467 19.3909 5.35822 19.2679 5.43463C14.982 8.11574 7.3742 12.8756 7.3742 12.8756Z" fill="currentColor" />
    </Icon>
  )
}

export default TelegramIcon
