import {
  Box,
  Flex,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton
} from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'
import { addAccount, selectSite } from '../store/slice/site'

const ModalAccount = ({ isOpen, onOpen, onClose }) => {
  const dispatch = useDispatch()
  const state = useSelector(selectSite)
  const list = [
    {
      code: 'demo01',
      name: 'Demo 01'
    },
    {
      code: 'demo02',
      name: 'Demo 02'
    },
    {
      code: 'demo03',
      name: 'Demo 03'
    },
    {
      code: 'demo04',
      name: 'Demo 04'
    },
    {
      code: 'demo05',
      name: 'Demo 05'
    },
  ]

  const changeAccount = (val) => {
    dispatch(addAccount(val))
    onClose()
  }
  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        background="#2B2B2B"
        size={{ base: 'xs', md: 'md' }}
      >
        <ModalOverlay />
        <ModalContent borderRadius='30px' overflow='hidden' background='#2B2B2B'>
          <Box>

            <ModalHeader background='#2B2B2B' color='#fff'>Please select a demo account</ModalHeader>
            <ModalCloseButton color='#FFCD44' w='40px' h='40px' />
          </Box>
          <ModalBody borderTopRadius='30px' background='#FFFFFF'>
            <Box py='1.7rem' px='2rem'>

              <Text fontSize='sm' mb='1.5rem' align='center'>
                If there is no balance, please change other accounts or negotiate business stored value
              </Text>

              <Flex direction='column' gap='1rem'>
                {
                  list.map((item, i) => {
                    return (
                      <Button
                        key={item.code + i}
                        borderRadius='1.2rem'
                        background='#E8E7EA'
                        color={state.account === item.code ? '#fff' : '#979797'}
                        onClick={() => changeAccount(item.code)}
                        bgGradient={state.account === item.code ? 'linear(to-r, #104B83 30.91%, #0088B3 100%)' : ''}
                        _hover={{
                          // bgGradient: 'linear(to-r, #104B83 30.91%, #0088B3 100%)',
                          opacity: 0.5
                        }}
                      >
                        {item.name}
                      </Button>
                    )
                  })
                }
              </Flex>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ModalAccount