import {
  Box,
  Flex,
  Text,
  Image
} from '@chakra-ui/react'
// Redux
import { useSelector } from 'react-redux'
import { selectSite } from '../store/slice/site'

export default function Preloader() {
  const state = useSelector(selectSite)
  
  
  return (
    <Box
      pos='fixed'
      top={0}
      left={0}
      w={'full'}
      h={'full'}
      zIndex={5}
      className={`isLoading ${state.isLoading ? 'active' : ''}`}
      display={state.isLoading ? 'block' : 'none'}
      sx={{
        '@keyframes loadingDots': {
          '0%': { content: "'LOADING'" },
          '25%': { content: "'LOADING.'" },
          '50%': { content: "'LOADING..'" },
          '75%': { content: "'LOADING...'" },
          '100%': { content: "'LOADING'" }
        }
      }}
    >
      <Flex
        justify='center'
        alignItems='center'
        w={'full'}
        h={'full'}
        bg={'blackAlpha.800'}
      >
        <Box 
          position="relative" 
          textAlign="center"
          display={'flex'}
          flexDir={'column'}
          alignItems={'center'}
        >
          <Image
            width='150px'
            height='150px'
            src='/static/Loader.svg'
            alt='Preloader'
          />
          <Box position="relative" width="200px" height="40px">
            <Text
              color={'#979797'}
              fontWeight={'bold'}
              fontSize={'1.4rem'}
              position="absolute"
              left="50%"
              transform="translateX(-50%)"
              width="200px"
              whiteSpace="nowrap"
              sx={{
                '&::after': {
                  content: "'Loading'",
                  animation: 'loadingDots 1.5s infinite'
                }
              }}
            />
          </Box>
        </Box>
      </Flex>
    </Box>
  )
}