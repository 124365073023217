import { Icon } from '@chakra-ui/react';

const SportIcon = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M13.4365 19.2682C12.399 19.2682 11.5551 18.3838 11.5551 17.2966C11.5551 16.2094 12.399 15.325 13.4365 15.325C14.474 15.325 15.318 16.2094 15.318 17.2966C15.318 18.3838 14.474 19.2682 13.4365 19.2682ZM13.4365 14.5364C11.9844 14.5364 10.8024 15.775 10.8024 17.2966C10.8024 18.8183 11.9844 20.0569 13.4365 20.0569C14.8887 20.0569 16.0706 18.8183 16.0706 17.2966C16.0706 15.775 14.8887 14.5364 13.4365 14.5364Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M17.9521 12.1703C16.9146 12.1703 16.0706 11.2859 16.0706 10.1987C16.0706 9.11154 16.9146 8.22714 17.9521 8.22714C18.9896 8.22714 19.8336 9.11154 19.8336 10.1987C19.8336 11.2859 18.9896 12.1703 17.9521 12.1703ZM17.9521 7.43848C16.5 7.43848 15.3181 8.67701 15.3181 10.1987C15.3181 11.7204 16.5 12.959 17.9521 12.959C19.4043 12.959 20.5862 11.7204 20.5862 10.1987C20.5862 8.67701 19.4043 7.43848 17.9521 7.43848Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M4.78154 11.382C3.7441 11.382 2.90006 10.4975 2.90006 9.41041C2.90006 8.32326 3.7441 7.43877 4.78154 7.43877C5.819 7.43877 6.66305 8.32326 6.66305 9.41041C6.66305 10.4975 5.819 11.382 4.78154 11.382ZM4.78154 6.65015C3.32942 6.65015 2.14746 7.88872 2.14746 9.41041C2.14746 10.9321 3.32942 12.1706 4.78154 12.1706C6.2337 12.1706 7.41564 10.9321 7.41564 9.41041C7.41564 7.88872 6.2337 6.65015 4.78154 6.65015Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M23.11 15.9978L22.5779 15.4402L20.9624 17.133L19.347 15.4402L18.8148 15.9978L20.4303 17.6906L18.8148 19.3834L19.347 19.941L20.9624 18.2482L22.5779 19.941L23.11 19.3834L21.4945 17.6906L23.11 15.9978Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M4.29507 0.619096L3.76298 0.0615234L2.14754 1.75434L0.532086 0.0615234L0 0.619096L1.61545 2.31192L0 4.00474L0.532086 4.56229L2.14754 2.86949L3.76298 4.56229L4.29507 4.00474L2.67962 2.31192L4.29507 0.619096Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M21.6048 2.59151L21.0727 2.03394L19.4573 3.72676L17.8418 2.03394L17.3097 2.59151L18.9252 4.28433L17.3097 5.97715L17.8418 6.5347L19.4573 4.8419L21.0727 6.5347L21.6048 5.97715L19.9893 4.28433L21.6048 2.59151Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M4.78164 21.2397C4.78164 22.3268 3.93759 23.2113 2.90014 23.2113C1.86269 23.2113 1.01865 22.3268 1.01865 21.2397C1.01865 20.1525 1.86269 19.2681 2.90014 19.2681C3.93759 19.2681 4.78164 20.1525 4.78164 21.2397ZM13.9814 6.69159L11.7236 5.50866C11.6258 5.45737 11.5121 5.45341 11.4113 5.49719C11.3104 5.54093 11.2325 5.62853 11.1987 5.73657L10.446 8.10248L11.1595 8.35208L11.6273 6.88128C11.663 6.95621 11.6958 7.02404 11.7228 7.07684C11.9373 7.49679 12.016 7.97039 11.9855 8.65928C11.9177 10.1924 11.3962 11.737 10.5164 13.0087C10.3411 13.2618 10.1176 13.507 9.88464 13.7567C9.1945 14.4967 8.31245 15.0228 7.37845 15.5796C6.0494 16.3722 4.68529 17.1991 3.90411 18.6884C3.59404 18.5544 3.25537 18.4795 2.90014 18.4795C1.44801 18.4795 0.266052 19.718 0.266052 21.2397C0.266052 22.7614 1.44801 23.9999 2.90014 23.9999C4.35228 23.9999 5.53423 22.7614 5.53423 21.2397C5.53423 20.3718 5.14927 19.5974 4.54983 19.091C5.22567 17.7823 6.45054 17.0406 7.75061 16.2653C8.68874 15.7058 9.65883 15.1273 10.4231 14.3083C10.6763 14.0366 10.9206 13.7681 11.1256 13.4715C12.0905 12.0768 12.6632 10.3805 12.7373 8.6955C12.7614 8.14861 12.743 7.43608 12.4058 6.74799L13.6446 7.39706L13.9814 6.69159Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M8.9552 0L8.35425 0.473582C8.46033 0.62224 8.59881 0.837147 8.75463 1.09582L9.39091 0.674684C9.22234 0.394716 9.07559 0.167587 8.9552 0Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M11.223 4.17969L10.5412 4.51329C10.6649 4.79169 10.7609 5.02631 10.8268 5.21044L11.5312 4.9344C11.459 4.73093 11.3551 4.47742 11.223 4.17969Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M9.78841 1.36035L9.14044 1.76098C9.26159 1.97707 9.38652 2.20499 9.51149 2.43843L10.1677 2.05238C10.0405 1.81421 9.91258 1.58117 9.78841 1.36035Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M10.5333 2.75269L9.86993 3.12374C9.98922 3.35876 10.1059 3.5922 10.215 3.81696L10.8859 3.4597C10.7746 3.23021 10.656 2.99204 10.5333 2.75269Z" fill="currentColor" />
    </Icon>
  )
}

export default SportIcon
