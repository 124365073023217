import { createSlice } from '@reduxjs/toolkit';

// 隨機抽選當前使用的帳號
const getRandomAccount = () => {
  const randomNumber = Math.floor(Math.random() * 5) + 1; // 生成1到5之间的随机整数
  return `demo0${randomNumber}`;
};

export const siteSlice = createSlice({
  name: 'site',
  initialState: {
    isLoading: false, // PreLoader
    account: getRandomAccount(), // 當前使用帳號
    vendors: [], // 當前類型的產品商們
    activeVendor: {}, // 當前選取的產品商
    language: 'zh',  // 語系
    type: '', // 當前的遊戲類型
    theme: 'light', // 主題色（light/dark）
    searchGame: '', // 搜尋遊戲的字
  },
  reducers: {
    switchLoader: (state, action) => {
      state.isLoading = action.payload
    },
    addAccount: (state, action) => {
      state.account = action.payload
    },
    addVendor: (state, action) => {
      // state.vendors = action.payload
      // console.log('action?.payload', action?.payload)

      if (action?.payload?.length > 0) {
        const sortVendors = [...action?.payload].sort(function (a, b) {
          return a?.sort - b?.sort
        })

        state.vendors = sortVendors

        // state?.vendors.forEach(vendor => {
        //   console.log(vendor?.name_zh, ' 排序sort：', vendor?.sort)
        // })
      }
    },
    addActiveVendor: (state, action) => {
      state.activeVendor = action.payload
    },
    addLanguage: (state, action) => {
      state.language = action.payload
    },
    addType: (state, action) => {
      state.type = action.payload
    },
    addTheme: (state, action) => {
      state.theme = action.payload
    },
    addSearchGame: (state, action) => {
      state.searchGame = action.payload
    },
  }
})

export const selectSite = (state) => state.site
export const {
  switchLoader,
  addAccount,
  addMenu,
  addVendor,
  addActiveVendor,
  addLanguage,
  addType,
  addTheme,
  addSearchGame,
} = siteSlice.actions

export default siteSlice.reducer