import { Icon, Box } from '@chakra-ui/react'

const Games = (props) => {
  return (
    <Box>
      <Icon
        viewBox="0 0 421 87"
        fill="none"
        {...props}
        transition={'all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.25s'}
      >
        <path d="M60.36 27.84C58.84 25.04 56.64 22.92 53.76 21.48C50.96 19.96 47.64 19.2 43.8 19.2C37.16 19.2 31.84 21.4 27.84 25.8C23.84 30.12 21.84 35.92 21.84 43.2C21.84 50.96 23.92 57.04 28.08 61.44C32.32 65.76 38.12 67.92 45.48 67.92C50.52 67.92 54.76 66.64 58.2 64.08C61.72 61.52 64.28 57.84 65.88 53.04H39.84V37.92H84.48V57C82.96 62.12 80.36 66.88 76.68 71.28C73.08 75.68 68.48 79.24 62.88 81.96C57.28 84.68 50.96 86.04 43.92 86.04C35.6 86.04 28.16 84.24 21.6 80.64C15.12 76.96 10.04 71.88 6.35999 65.4C2.75999 58.92 0.959991 51.52 0.959991 43.2C0.959991 34.88 2.75999 27.48 6.35999 21C10.04 14.44 15.12 9.36 21.6 5.76C28.08 2.08 35.48 0.239998 43.8 0.239998C53.88 0.239998 62.36 2.68 69.24 7.56C76.2 12.44 80.8 19.2 83.04 27.84H60.36Z"
          fill="white"
        />
        <path d="M148.286 70.56H116.846L111.806 85.44H90.3262L120.806 1.2H144.566L175.046 85.44H153.326L148.286 70.56ZM143.006 54.72L132.566 23.88L122.246 54.72H143.006Z"
          fill="white"
        />
        <path d="M279.723 1.2V85.44H259.203V34.92L240.363 85.44H223.803L204.843 34.8V85.44H184.323V1.2H208.563L232.203 59.52L255.603 1.2H279.723Z"
          fill="white"
        />
        <path d="M314.999 17.64V34.68H342.479V50.52H314.999V69H346.079V85.44H294.479V1.2H346.079V17.64H314.999Z"
          fill="white"
        />
        <path d="M389.881 86.28C383.721 86.28 378.201 85.28 373.321 83.28C368.441 81.28 364.521 78.32 361.561 74.4C358.681 70.48 357.161 65.76 357.001 60.24H378.841C379.161 63.36 380.241 65.76 382.081 67.44C383.921 69.04 386.321 69.84 389.281 69.84C392.321 69.84 394.721 69.16 396.481 67.8C398.241 66.36 399.121 64.4 399.121 61.92C399.121 59.84 398.401 58.12 396.961 56.76C395.601 55.4 393.881 54.28 391.801 53.4C389.801 52.52 386.921 51.52 383.161 50.4C377.721 48.72 373.281 47.04 369.841 45.36C366.401 43.68 363.441 41.2 360.961 37.92C358.481 34.64 357.241 30.36 357.241 25.08C357.241 17.24 360.081 11.12 365.761 6.72C371.441 2.24 378.841 0 387.961 0C397.241 0 404.721 2.24 410.401 6.72C416.081 11.12 419.121 17.28 419.521 25.2H397.321C397.161 22.48 396.161 20.36 394.321 18.84C392.481 17.24 390.121 16.44 387.241 16.44C384.761 16.44 382.761 17.12 381.241 18.48C379.721 19.76 378.961 21.64 378.961 24.12C378.961 26.84 380.241 28.96 382.801 30.48C385.361 32 389.361 33.64 394.801 35.4C400.241 37.24 404.641 39 408.001 40.68C411.441 42.36 414.401 44.8 416.881 48C419.361 51.2 420.601 55.32 420.601 60.36C420.601 65.16 419.361 69.52 416.881 73.44C414.481 77.36 410.961 80.48 406.321 82.8C401.681 85.12 396.201 86.28 389.881 86.28Z"
          fill="white"
        />
      </Icon >
    </Box>

  )
}

export default Games