import React, { useState, useEffect } from "react";
import { useRouter } from 'next/router'
import { useDisclosure } from '@chakra-ui/react'
import { Box, Flex, Button, Spacer, List, ListItem, Text, Image } from "@chakra-ui/react";
// Swiper
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// Redux
import { useSelector, useDispatch } from 'react-redux';
import {
  selectSite,
  addLanguage,
  addType,
  addVendor,
  addActiveVendor
} from '../store/slice/site';
// Components
import PersonIcon from '../components/icons/Person'
import MenuIcon from './icons/Menu1'
import ModalAccount from '../components/ModalAccount'
import ModalMenu from '../components/ModalMenu'
// Css
import styles from '../styles/Navbar.module.scss'

const NavBar = ({ data, error }) => {
  const dispatch = useDispatch()
  const state = useSelector(selectSite)
  const router = useRouter()

  const [active, setActive] = useState('') // 當前 active 的遊戲類型
  const [activeType, setActiveType] = useState({}) // 當前 active 遊戲類型的 banner、vendors 資料
  const {
    isOpen: accountIsOpen,
    onOpen: accountOnOpen,
    onClose: accountOnClose } = useDisclosure() // 控制 切換帳號燈箱
  const {
    isOpen: menuIsOpen,
    onOpen: menuOnOpen,
    onClose: menuOnClose } = useDisclosure() // 控制 H5 選單燈箱

  // Swiper 左右側箭頭
  const [prevEl, setPrevEl] = useState(null)
  const [nextEl, setNextEl] = useState(null)

  /*--- 切換語系 ---*/
  const handleLanguage = (val) => {
    dispatch(addLanguage(val))
  }


  /*--- 切換當前類型 ---*/
  const handleType = (type, val) => {
    // type='type' - 點擊 Navbar 遊戲類型
    // type='vendor' - 點擊 Navbar 子選單的單一產品商
    if (type === 'type') {
      dispatch(addType(val))
      const temp = data?.menus.filter(item => item.code === val)
      dispatch(addActiveVendor(temp[0].vendors[0]))
      dispatch(addVendor(temp[0].vendors))

      // 跳轉
      const href = `/${val}/${temp[0]?.vendors[0]?.code}`
      router.push(href)
    } else {
      // Type = vendor
      const temp = data?.menus.find(item => {
        return item.vendors.find(vendor => vendor.code === val)
      })

      dispatch(addType(temp.code))
      dispatch(addVendor(temp.vendors))

      // 更新 Redux 目前的 ActiveVendor
      const activeVendor = temp.vendors.filter(vendor => vendor.code === val)
      dispatch(addActiveVendor(activeVendor[0]))

      console.log('activeVendor', activeVendor)
      const href = `/${temp.code}/${val}`
      router.push(href)
    }

  }

  /*--- 點選 Logo 回首頁 ---*/
  const handleLogo = () => {
    dispatch(addType(data?.menus[0]?.code))
    const href = `/${data?.menus[0]?.code}/${data?.menus[0]?.vendors[0]?.code}`
    router.push(href)
  }


  // 根據類型顯示相對應的選單圖片
  useEffect(() => {
    if (active) {
      const temp = data?.menus?.find(item => item.code === active)
      setActiveType(temp)
    }
  }, [active, data?.menus])

  if (error) return `${error.message}`

  return (
    <Box position='relative' className={styles.menu} onMouseLeave={() => {
      setActive('')
      setActiveType({})
    }}>
      <Box>
        <Flex
          align="center"
          px={{ base: '1rem', md: '1.5rem', lg: '2.5rem', xl: '6.5rem' }}
          pt={{ base: '0.9rem', md: '1.2rem', lg: '1.5rem' }}
          pb="1rem"
          className={'wow fadeInUp'}
          data-wow-duration='1s'
          data-wow-delay="0.5s"
        >
          {/* Logo */}
          <Box onClick={() => handleLogo()} onMouseEnter={() => setActive('')}>
            <Image
              cursor={'pointer'}
              src={data?.image_url + '/' + data?.setting.logo_light}
              alt='logo'
              width={{ base: '85px', md: '136px', xl: '160px' }}
            />
          </Box>

          <Spacer />

          <Box display={{ base: 'none', lg: 'flex' }} alignItems='center' zIndex='99'>
            {/* web 遊戲類型選單 */}
            <List display='flex' className={`${styles['main-menu']} hazel123`}>
              {
                data?.menus?.length > 0 && data?.menus.map((item, i) => {
                  return (
                    <ListItem
                      p='4'
                      key={item.code + i + Math.random()}
                      onClick={() => handleType('type', item.code)}
                      onMouseEnter={() => setActive(item.code)}
                    >
                      <Box
                        className={styles['main-menu-item']}
                        color="#fff"
                        cursor='pointer'
                        position='relative'
                        fontWeight='500'
                        transition='.4s ease-in-out'
                        fontSize={{ base: '12px', xl: '1rem' }}
                        _after={{
                          content: '""',
                          display: 'block',
                          position: 'absolute',
                          height: '2px',
                          width: '80%',
                          background: state.type === item.code ? '#fff' : 'transparent',
                          transition: '.4s ease-in-out',
                          bottom: '-.5rem',
                          left: '10%'
                        }}
                        _hover={{
                          color: '#2B2B2B'
                        }}
                      >
                        {state?.language && state.language === 'zh' ? item.name_zh : item.name_en}
                      </Box>
                    </ListItem>
                  )
                })
              }
            </List>

            {/* web 語系、切換帳號 Icon */}
            <Flex pr='4' onMouseEnter={() => setActive('')}>
              <Button
                background={state.language === 'zh' ? '#2B2B2B' : '#fff'}
                color={state.language === 'zh' ? '#fff' : '#2B2B2B'}
                borderRadius='50px'
                size='sm'
                mr='.5rem'
                onClick={() => handleLanguage('zh')}
                className={'wow fadeInUp'}
                data-wow-duration='1s'
                data-wow-delay="0.5s"
              >
                簡中
              </Button>
              <Button
                background={state.language === 'zh' ? '#fff' : '#2B2B2B'}
                color={state.language === 'zh' ? '#2B2B2B' : '#fff'}
                borderRadius='50px'
                size='sm'
                onClick={() => handleLanguage('en')}
                className={'wow fadeInUp'}
                data-wow-duration='1s'
                data-wow-delay="0.5s"
              >
                English
              </Button>
              <Flex align='center' justify='center' ml='1rem' cursor='pointer' onClick={accountOnOpen}>
                <PersonIcon
                  boxSize='2rem'
                  color='#2B2B2B'
                />
              </Flex>
            </Flex>
          </Box>

          {/* 768 以下顯示 */}
          <Flex display={{ base: 'flex', sm: 'flex', lg: 'none' }} align="center">
            <Box onClick={accountOnOpen} cursor='pointer' mr='1rem'>
              <PersonIcon
                boxSize={{ base: '1.5rem', md: '1.8rem' }}
                color='#2B2B2B'
              />
            </Box>
            <Box onClick={menuOnOpen} cursor='pointer'>
              <MenuIcon
                boxSize={{ base: '1.5rem', md: '1.8rem' }}
                color='#2B2B2B'
              />
            </Box>
          </Flex>
        </Flex >

        {/* 帳號切換燈箱 */}
        <ModalAccount
          isOpen={accountIsOpen}
          onOpen={accountOnOpen}
          onClose={accountOnClose}
        />

        {/* H5 選單燈箱 */}
        <ModalMenu
          key='menu-modal'
          data={data}
          isOpen={menuIsOpen}
          onOpen={menuOnOpen}
          onClose={menuOnClose}
        />
      </Box>

      {/* web 選單底線 */}
      <Box w='100%' h='1px' bgColor='#fff' position='relative' zIndex='6' />

      {/* web 子選單區塊 */}
      <Box
        className={active ? styles['sub-menu'] : styles['sub-menu-none']}
        width='100vw'
        background='rgba(43, 43, 43, 0.7)'
        backdropFilter='blur(15px)'
        position='absolute'
        right='0'
        zIndex='999'
        onMouseLeave={() => {
          setActive('')
          setActiveType({})
        }}
      >
        {
          activeType?.vendors?.length > 0 && (
            <Swiper
              opacity={active ? 1 : 0}
              className={styles['sub-menu-item']}
              spaceBetween={23}
              navigation={{ prevEl, nextEl }}
              modules={[Navigation]}
              slidesPerView='auto'
              transition='all 1s ease-in-out 5s'
              style={{
                paddingTop: '1rem',
                paddingLeft: '1rem',
                width: '90%'
              }}
            >
              {
                activeType?.vendors?.length > 0 && activeType?.vendors?.map(item => {
                  return (
                    <SwiperSlide
                      key={'menu-slider-' + item.code}
                      style={{ width: 'auto' }}
                    >
                      <Flex
                        direction='column'
                        align='center'
                        justify='center'
                        width='180px'
                        cursor={'pointer'}
                        onClick={() => handleType('vendor', item.code)}
                      >
                        <Box
                          backgroundImage={data?.image_url + '/' + activeType.menu_image}
                          backgroundPosition={`${-180 * (item.sprite_sort - 1)}px  0`}
                          bgRepeat={'no-repeat'}
                          bgSize={'auto 207px'}
                          w={'180px'}
                          h={'207px'}
                        />
                        <Text
                          align='center'
                          color='#fff'
                          width='100%'
                          fontWeight='500'
                          letterSpacing='1px'
                          style={{
                            WebkitBoxOrient: 'vertical',
                            display: '-webkit-box',
                            WebkitLineClamp: '2',
                            overflow: 'hidden'
                          }}
                        >{state.language === 'zh' ? item.name_zh : item.name_en}
                        </Text>
                      </Flex>
                    </SwiperSlide>
                  )
                })
              }
            </Swiper>

          )
        }

        {/* Swiper 箭頭 */}
        <Box>
          <Box
            className='swiper-button-prev'
            style={{
              zIndex: 1,
              left: '20px',
              color: 'white'
            }}
            ref={(node) => setPrevEl(node)}
          >
          </Box>
          <Box
            className='swiper-button-next'
            style={{
              zIndex: 1,
              right: '20px',
              color: 'white'
            }}
            ref={(node) => setNextEl(node)}>
          </Box>
        </Box>
      </Box>
    </Box >
  );
};



export default NavBar;