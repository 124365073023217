// Next
import Script from 'next/script'

/*
  插入 Google Analytics (GA) 分析 Script
*/

export default function GaScript() {
  return (
    <>
      {/* Google tag (gtag.js) */}
      <Script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-N2JJVVSPQ2"
        strategy="afterInteractive"
      />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){window.dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', 'G-N2JJVVSPQ2');
      `}
      </Script>
    </>
  )
}