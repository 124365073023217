import { Box, Text, Flex, Link } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { selectSite } from '../store/slice/site'

import SkypeIcon from '../components/icons/Skype'
import TelegramIcon from '../components/icons/Telegram'
import { useState } from 'react'
import styles from '../styles/Cs.module.css'

const Cs = ({ data }) => {
  const state = useSelector(selectSite)
  let [active, setActive] = useState('')

  return (
    <Flex
      className={styles.cs}
      direction='row-reverse'
      position='relative'
      onMouseLeave={() => setActive('')}
    >
      <Flex
        background='rgba(0, 0, 0, 0.3)'
        direction='column'
        py='1rem'
        px='.5rem'
        borderRadius='50px'
        gap='1rem'
        backdropFilter="auto"
        backdropBlur="15px"
        boxShadow='2xl'
        transition='0.5s'
      >

        <SkypeIcon
          display={data?.contact.skype?.length > 0}
          boxSize='2rem'
          color='#fff'
          cursor='pointer'
          transition='0.5s'
          onClick={() => setActive('skype')}
          onMouseEnter={() => setActive('skype')}
          opacity={active === 'skype' ? 1 : 0.5}
          _hover={{
            opacity: 1
          }}
        />
        <TelegramIcon
          display={data?.contact.telegram?.length > 0}
          boxSize='2rem'
          color='#fff'
          cursor='pointer'
          transition='0.5s'
          onClick={() => setActive('telegram')}
          onMouseEnter={() => setActive('telegram')}
          opacity={active === 'telegram' ? 1 : 0.5}
          _hover={{
            opacity: 1
          }}
        />
      </Flex>
      <Box
        className={styles.container}
        onMouseLeave={() => setActive('')}
        position='relative'
        pr='2rem'
      >
        <Box
          display='inline-block'
          className={styles.list}
          background='rgba(0, 0, 0, 0.3)'
          p={data?.contact[active]?.length > 0 ? '0.5rem 1rem' : '0'}
          borderRadius='4px'
          backdropFilter="auto"
          backdropBlur="15px"
          boxShadow='2xl'
          top={active === 'skype' ? '-1rem' : '4rem'}
        >
          {
            data?.contact[active]?.length > 0
            && data?.contact[active].map((item, i) => {
              return (
                <Box
                  key={`${active}-${i}`}
                  color="#fff"
                  opacity='0.5'
                  cursor="pointer"
                  transition='.5s'
                  fontSize='1rem'
                  fontWeight='600'
                  my='.6rem'
                  _hover={{
                    opacity: 1
                  }}
                >
                  <Link
                    isExternal
                    scroll="false"
                    href={active === 'skype' ? `skype:${item.link}?chat` : `tg://resolve?domain=${item.link}`}
                  >
                    {state.language === 'zh' ? item.name_zh : item.name_en}
                  </Link>
                </Box>
              )
            })
          }
        </Box>
      </Box>
    </Flex>
  )
}

export default Cs