// React
import { useEffect } from 'react'
import { ChakraProvider } from '@chakra-ui/react'
// Css
import '../styles/globals.scss'
import 'animate.css'
// Redux
import { Provider } from 'react-redux';
import store from '../store'
import theme from '../theme'
import { interceptor } from '../utils/request'
interceptor(store)
// Component
import Layout from '../components/Layout'
import PinnedIcon from '../components/PinnedIcon'
import GaScript from '../components/Analytics/GaScript'
import Preloader from '../components/Preloader'
// Library
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// React Query
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'


function MyApp({ Component, pageProps }) {
  const queryClient = new QueryClient();
  // 全局引入 wowjs
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.WOW = require('wowjs');
    }
    new WOW.WOW({ live: true }).init();

  }, [])


  return (
    <QueryClientProvider client={queryClient}>
      <GaScript />
      <Provider store={store}>
        <ChakraProvider theme={theme} pos={'relative'}>
          <Layout>
            <Component {...pageProps} />
            <ToastContainer />
            <Preloader />
          </Layout>
          <ReactQueryDevtools />
        </ChakraProvider>
      </Provider>
    </QueryClientProvider>
  )
}

export default MyApp
