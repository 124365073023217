import { Icon, Box } from '@chakra-ui/react';

const SportsIcon = (props) => {
  return (
    <Box>
      <Icon
        viewBox="0 0 456 87"
        fill="none"
        {...props}
        transition={'all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.25s'}
      >
        <path d="M32.92 86.84C26.76 86.84 21.24 85.84 16.36 83.84C11.48 81.84 7.55999 78.88 4.59999 74.96C1.71999 71.04 0.199993 66.32 0.0399933 60.8H21.88C22.2 63.92 23.28 66.32 25.12 68C26.96 69.6 29.36 70.4 32.32 70.4C35.36 70.4 37.76 69.72 39.52 68.36C41.28 66.92 42.16 64.96 42.16 62.48C42.16 60.4 41.44 58.68 40 57.32C38.64 55.96 36.92 54.84 34.84 53.96C32.84 53.08 29.96 52.08 26.2 50.96C20.76 49.28 16.32 47.6 12.88 45.92C9.43999 44.24 6.47999 41.76 3.99999 38.48C1.51999 35.2 0.279993 30.92 0.279993 25.64C0.279993 17.8 3.11999 11.68 8.79999 7.28C14.48 2.8 21.88 0.559998 31 0.559998C40.28 0.559998 47.76 2.8 53.44 7.28C59.12 11.68 62.16 17.84 62.56 25.76H40.36C40.2 23.04 39.2 20.92 37.36 19.4C35.52 17.8 33.16 17 30.28 17C27.8 17 25.8 17.68 24.28 19.04C22.76 20.32 22 22.2 22 24.68C22 27.4 23.28 29.52 25.84 31.04C28.4 32.56 32.4 34.2 37.84 35.96C43.28 37.8 47.68 39.56 51.04 41.24C54.48 42.92 57.44 45.36 59.92 48.56C62.4 51.76 63.64 55.88 63.64 60.92C63.64 65.72 62.4 70.08 59.92 74C57.52 77.92 54 81.04 49.36 83.36C44.72 85.68 39.24 86.84 32.92 86.84Z" fill="white" />
        <path d="M140.468 28.88C140.468 33.76 139.348 38.24 137.108 42.32C134.868 46.32 131.428 49.56 126.788 52.04C122.148 54.52 116.388 55.76 109.508 55.76H96.7881V86H76.2681V1.76H109.508C116.228 1.76 121.908 2.92 126.548 5.23999C131.188 7.55999 134.668 10.76 136.988 14.84C139.308 18.92 140.468 23.6 140.468 28.88ZM107.948 39.44C111.868 39.44 114.788 38.52 116.708 36.68C118.628 34.84 119.588 32.24 119.588 28.88C119.588 25.52 118.628 22.92 116.708 21.08C114.788 19.24 111.868 18.32 107.948 18.32H96.7881V39.44H107.948Z" fill="white" />
        <path d="M190.991 86.84C183.071 86.84 175.791 85 169.151 81.32C162.591 77.64 157.351 72.52 153.431 65.96C149.591 59.32 147.671 51.88 147.671 43.64C147.671 35.4 149.591 28 153.431 21.44C157.351 14.88 162.591 9.76 169.151 6.08C175.791 2.4 183.071 0.559998 190.991 0.559998C198.911 0.559998 206.151 2.4 212.711 6.08C219.351 9.76 224.551 14.88 228.311 21.44C232.151 28 234.071 35.4 234.071 43.64C234.071 51.88 232.151 59.32 228.311 65.96C224.471 72.52 219.271 77.64 212.711 81.32C206.151 85 198.911 86.84 190.991 86.84ZM190.991 68.12C197.711 68.12 203.071 65.88 207.071 61.4C211.151 56.92 213.191 51 213.191 43.64C213.191 36.2 211.151 30.28 207.071 25.88C203.071 21.4 197.711 19.16 190.991 19.16C184.191 19.16 178.751 21.36 174.671 25.76C170.671 30.16 168.671 36.12 168.671 43.64C168.671 51.08 170.671 57.04 174.671 61.52C178.751 65.92 184.191 68.12 190.991 68.12Z" fill="white" />
        <path d="M288.447 86L270.927 54.2H266.007V86H245.487V1.76H279.927C286.567 1.76 292.207 2.92 296.847 5.23999C301.567 7.55999 305.087 10.76 307.407 14.84C309.727 18.84 310.887 23.32 310.887 28.28C310.887 33.88 309.287 38.88 306.087 43.28C302.967 47.68 298.327 50.8 292.167 52.64L311.607 86H288.447ZM266.007 39.68H278.727C282.487 39.68 285.287 38.76 287.127 36.92C289.047 35.08 290.007 32.48 290.007 29.12C290.007 25.92 289.047 23.4 287.127 21.56C285.287 19.72 282.487 18.8 278.727 18.8H266.007V39.68Z" fill="white" />
        <path d="M384.368 1.76V18.2H362.048V86H341.528V18.2H319.208V1.76H384.368Z" fill="white" />
        <path d="M425.147 86.84C418.987 86.84 413.467 85.84 408.587 83.84C403.707 81.84 399.787 78.88 396.827 74.96C393.947 71.04 392.427 66.32 392.267 60.8H414.107C414.427 63.92 415.507 66.32 417.347 68C419.187 69.6 421.587 70.4 424.547 70.4C427.587 70.4 429.987 69.72 431.747 68.36C433.507 66.92 434.387 64.96 434.387 62.48C434.387 60.4 433.667 58.68 432.227 57.32C430.867 55.96 429.147 54.84 427.067 53.96C425.067 53.08 422.187 52.08 418.427 50.96C412.987 49.28 408.547 47.6 405.107 45.92C401.667 44.24 398.707 41.76 396.227 38.48C393.747 35.2 392.507 30.92 392.507 25.64C392.507 17.8 395.347 11.68 401.027 7.28C406.707 2.8 414.107 0.559998 423.227 0.559998C432.507 0.559998 439.987 2.8 445.667 7.28C451.347 11.68 454.387 17.84 454.787 25.76H432.587C432.427 23.04 431.427 20.92 429.587 19.4C427.747 17.8 425.387 17 422.507 17C420.027 17 418.027 17.68 416.507 19.04C414.987 20.32 414.227 22.2 414.227 24.68C414.227 27.4 415.507 29.52 418.067 31.04C420.627 32.56 424.627 34.2 430.067 35.96C435.507 37.8 439.907 39.56 443.267 41.24C446.707 42.92 449.667 45.36 452.147 48.56C454.627 51.76 455.867 55.88 455.867 60.92C455.867 65.72 454.627 70.08 452.147 74C449.747 77.92 446.227 81.04 441.587 83.36C436.947 85.68 431.467 86.84 425.147 86.84Z" fill="white" />
      </Icon>
    </Box>
  )
}

export default SportsIcon